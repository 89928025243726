/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default-member */
/**
 * #######################################################@
 *
 * Charts settings
 *
 * #######################################################@
 */
import "./style.css";

import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Step,
	StepLabel,
	Stepper
} from "@mui/material";
import { useRef, useState } from "react";
import MDButton from "components/Basics/MDButton";
import MDBox from "components/Basics/MDBox";
import i18n from "i18n";
import { useDispatch } from "react-redux";
import FormActions from "redux-react/actions/formAction";

import ProductChoiceStep from "./steps/1. ProductChoiceStep";
import ProductSettingsStep from "./steps/2. ProductSettings";
import ProductreviewStep from "./steps/3. ProductReview";

export default function AddProductDialog({ open, handleCloseDialog, handleSave }) {
	const topContainer = useRef(null);
	// Product skeleton
	const [newProduct, setNewProduct] = useState({});
	const dispatch = useDispatch();

	/**
	 * Active step
	 */
	const [activeStep, setActiveStep] = useState(0);
	/**
	 *
	 * Steps labels
	 */
	const [steps, setSteps] = useState([
		i18n.t("SETTINGS.PRODUCTS.chooseProduct"),
		i18n.t("SETTINGS.PRODUCTS.settings"),
		i18n.t("SETTINGS.PRODUCTS.review")
	]);
	/**
	 * Can user go to next step
	 */
	const [stepValid, setStepValid] = useState(false);
	/**
	 * Is last step
	 */
	const isLastStep = activeStep === steps.length - 1;
	/**
	 * Close modale
	 */
	function close(e, reason) {
		handleCloseDialog();
		/**
		 * On component close, reset all states
		 * Timeout to wait for animation end
		 */
		setTimeout(() => {
			setNewProduct({});
			setStepValid(false);
			setActiveStep(0);

			setSteps([
				i18n.t("SETTINGS.PRODUCTS.chooseProduct"),
				i18n.t("SETTINGS.PRODUCTS.settings"),
				i18n.t("SETTINGS.PRODUCTS.review")
			]);
		}, 400);
	}
	/**
	 * Submit product
	 */
	function sumbit() {
		handleSave(newProduct);
		close();
	}

	/**
	 * User can go to next step
	 */
	const validStep = (val = true) => {
		setStepValid(val);
	};

	/**
	 * User select product
	 */
	const handleSelectProduct = product => {
		setNewProduct(product);
	};

	/**
	 * User make settings of product
	 */
	const handleSettingsProduct = product => {
		setNewProduct(product);
	};
	/**
	 * Go to next step
	 */
	const handleNext = () => {
		if (activeStep === 1) {
			const onSuccess = res => {
				setActiveStep(activeStep + 1);
				setStepValid(false);
				topContainer?.current?.scrollIntoView();
			};
			dispatch(FormActions.getUnique(newProduct?.parameters?.name, onSuccess));
		} else {
			setActiveStep(activeStep + 1);
			setStepValid(false);
			topContainer?.current?.scrollIntoView();
		}
	};
	/**
	 * Go to previous step
	 */
	const handleBack = () => {
		setActiveStep(activeStep - 1);
		topContainer?.current?.scrollIntoView();
	};
	/**
	 * Get actual step content
	 */
	function getStepContent(stepIndex) {
		switch (stepIndex) {
			case 0:
				return (
					<ProductChoiceStep
						product={newProduct}
						handleSelectProduct={handleSelectProduct}
						validStep={validStep}
						handleNext={handleNext}
					/>
				);
			case 1:
				return (
					<ProductSettingsStep
						product={newProduct}
						handleSettingsProduct={handleSettingsProduct}
						validStep={validStep}
					/>
				);

			case 2:
				return (
					<ProductreviewStep
						product={newProduct}
						handleSettingsProduct={handleSettingsProduct}
						validStep={validStep}
					/>
				);

			default:
				return null;
		}
	}
	/**
	 * Main component
	 */
	return (
		<Dialog
			fullWidth
			maxWidth="lg"
			open={open}
			onClose={close}
			PaperProps={{
				sx: {
					height: "90vh"
				}
			}}
		>
			<DialogTitle>
				<MDBox>
					<Stepper activeStep={activeStep} alternativeLabel>
						{steps.map(label => (
							<Step key={label}>
								<StepLabel>{label}</StepLabel>
							</Step>
						))}
					</Stepper>
				</MDBox>
			</DialogTitle>
			<DialogContent>
				<div data-id="top-container" ref={topContainer}></div>

				<MDBox mt={2}>{getStepContent(activeStep)}</MDBox>
			</DialogContent>
			<DialogActions>
				<MDButton variant="outlined" color="info" onClick={close}>
					{i18n.t("SETTINGS.cancel")}
				</MDButton>

				{activeStep !== 0 && (
					<MDButton
						disabled={activeStep === 0}
						variant="contained"
						color="light"
						onClick={handleBack}
					>
						{i18n.t("SETTINGS.CHARTS.NEW.STEPS.back")}
					</MDButton>
				)}
				<MDButton
					disabled={!stepValid}
					variant="contained"
					color="info"
					onClick={!isLastStep ? handleNext : sumbit}
				>
					{isLastStep ? i18n.t("SETTINGS.add") : i18n.t("SETTINGS.CHARTS.NEW.STEPS.next")}
				</MDButton>
			</DialogActions>
		</Dialog>
	);
}
