import "./style.css";
import React, { useEffect, useState, useRef } from "react";
import { ClickAwayListener, Paper, Popper, Zoom } from "@mui/material";
import { ArrowRight } from "@mui/icons-material";
import MDTypography from "components/Basics/MDTypography";
import MDBox from "components/Basics/MDBox";

// levelLayer = display only the level layers
const DictionaryMenu = ({
	levelLayer = false,
	dictionary,
	anchorEl,
	handleClose,
	handleInsertText,
	placement = "bottom"
}) => {
	return (
		<Popper
			id={anchorEl ? "simple-popper" : ""}
			open={Boolean(anchorEl)}
			anchorEl={anchorEl}
			placement={placement}
			style={{
				zIndex: 10000
			}}
		>
			<ClickAwayListener onClickAway={handleClose}>
				<Paper className="paperDictMenu">
					{Object.keys(dictionary).map((key, index) => {
						const item = dictionary[key];

						return (
							<MDBox variant="gradient" key={index}>
								<MyMenuItem
									style={{ zIndex: 9999 }}
									path={key}
									item={item}
									handleAction={path => {
										handleInsertText(path);
									}}
									levelLayer={levelLayer}
								/>
							</MDBox>
						);
					})}
				</Paper>
			</ClickAwayListener>
		</Popper>
	);
};
/**
 *
 * @param {*} param0
 * @returns
 */
const SingleLevel = ({ item, path, handleAction }) => {
	return (
		<MDBox
			p={1}
			display="flex"
			justifyContent="space-between"
			alignItems="center"
			onClick={() => {
				handleAction(path);
			}}
			className="selection"
		>
			<MDTypography variant="button">{item.label.fr}</MDTypography>
		</MDBox>
	);
};

/**
 *
 * @param {*} param0
 * @returns
 */
const MultiLevel = ({ item, path, handleAction, levelLayer }) => {
	const children = item.items;
	const hasChildrenLevel =
		Object.keys(children).find(key => children[key].type === "level") ?? false;
	const [open, setOpen] = useState(false);
	const openRef = useRef(null);
	const prevOpen = useRef(open);

	useEffect(() => {
		if (prevOpen.current === true && open === false) {
			openRef.current.focus();
		}
		prevOpen.current = open;
	}, [open]);

	const renderChildren = (child, key) => {
		if (child.items) {
			return (
				<MultiLevel
					key={key}
					item={child}
					path={`${path}.${key}`}
					handleAction={path => handleAction(path)}
					levelLayer={levelLayer}
				/>
			);
		}

		// levelLayer = display only the level layers
		if (levelLayer) {
			return null;
		}

		return (
			<MDBox
				key={key}
				p={1}
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				onClick={() => {
					handleAction(`${path}.${key}`);
					setOpen(false);
				}}
				className="selection"
			>
				<MDTypography variant="button">{child.label.fr}</MDTypography>
			</MDBox>
		);
	};

	return (
		<MDBox
			onMouseLeave={() => {
				setOpen(false);
			}}
		>
			{/* Button */}
			<MDBox
				p={1}
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				ref={openRef}
				className={open ? "selection subMenuOpen" : "selection"}
				onMouseEnter={() => setOpen(true)}
				onClick={() => {
					if (levelLayer) {
						handleAction(path);
						setOpen(false);
					}
				}}
			>
				<MDTypography variant="button">{item.label.fr}</MDTypography>
				{(!levelLayer || (levelLayer && hasChildrenLevel)) && <ArrowRight />}
			</MDBox>
			{/* Button's menu */}
			<Popper
				open={open}
				anchorEl={openRef.current}
				placement="right"
				style={{
					zIndex: 10000
				}}
			>
				<Paper
					className="paperDictMenu"
					style={{
						zIndex: 10000
					}}
				>
					<MDBox variant="gradient">
						{Object.entries(children).map(([key, child]) => renderChildren(child, key))}
					</MDBox>
				</Paper>
			</Popper>
		</MDBox>
	);
};
/**
 *
 * @param {*} param0
 * @returns
 */
const MyMenuItem = ({ item, path, handleAction, levelLayer }) => {
	const Component = item.items && Object.keys(item.items).length > 0 ? MultiLevel : SingleLevel;
	return (
		<Component
			style={{ zIndex: 1 }}
			path={path}
			item={item}
			handleAction={handleAction}
			levelLayer={levelLayer}
		/>
	);
};
export default DictionaryMenu;
