import { Add, Delete } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Tooltip } from "chart.js";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import MDTypography from "components/Basics/MDTypography";
import i18n from "i18n";
import lod_ from "lodash";

export default function DictionaryCode({
	stringCode,
	setStringCode,
	stringLabel,
	setStringLabel,
	listCodes,
	showWarning,
	showWarningCode,
	stringWarnCode,
	stringWarnAdd,
	indexWarnCode,
	addCodes,
	updateCodes,
	showWarningLabel,
	updateLabel,
	deleteCodes
}) {
	return (
		<MDBox display="flex" width="40%">
			<hr style={{ borderLeft: "1px solid #ccc", height: "100%", margin: "0 10px" }} />
			<MDBox display="flex" flexDirection="column" width="100%">
				<MDTypography variant="h6">{i18n.t("FORMS.addCode")}</MDTypography>
				<MDBox display="flex" width="100%" alignItems="center" pt={1}>
					<MDBox width="45%" pr={1}>
						<MDInput
							label="Code"
							value={stringCode}
							onChange={e => setStringCode(e.target.value)}
						/>
					</MDBox>
					<MDBox width="45%">
						<MDInput
							label="Label"
							value={stringLabel}
							onChange={e => setStringLabel(e.target.value)}
						/>
					</MDBox>
					<MDBox pl={1}>
						<Tooltip
							open={showWarning}
							title={stringWarnAdd}
							placement="right"
							disableFocusListener
							disableHoverListener
							disableTouchListener
						>
							<IconButton size="small" onClick={() => addCodes()}>
								<Add />
							</IconButton>
						</Tooltip>
					</MDBox>
				</MDBox>

				<MDBox pt={1}>
					<MDTypography variant="h6">{i18n.t("FORMS.listCodes")}</MDTypography>
					{lod_.isEmpty(listCodes) && (
						<MDTypography variant="h6">{i18n.t("FORMS.emptyCodes")}</MDTypography>
					)}
					{listCodes.map((value, index) => (
						<MDBox key={index} display="flex" width="100%" alignItems="center" pt={1}>
							<MDBox width="45%" pr={1}>
								<Tooltip
									open={showWarningCode && index === indexWarnCode}
									title={stringWarnCode}
									placement="bottom"
									disableFocusListener
									disableHoverListener
									disableTouchListener
								>
									<MDInput
										label="Code"
										variant="standard"
										value={value.value}
										onChange={e => updateCodes(index, e.target.value)}
										readOnly
									/>
								</Tooltip>
							</MDBox>
							<MDBox width="45%">
								<Tooltip
									open={showWarningLabel && index === indexWarnCode}
									title={i18n.t("FORMS.labelRequired")}
									placement="bottom"
									disableFocusListener
									disableHoverListener
									disableTouchListener
								>
									<MDInput
										label="Label"
										variant="standard"
										value={value.label}
										onChange={e => updateLabel(index, e.target.value)}
										readOnly
									/>
								</Tooltip>
							</MDBox>
							<MDBox pl={1}>
								<IconButton size="small" onClick={() => deleteCodes(index)}>
									<Delete />
								</IconButton>
							</MDBox>
						</MDBox>
					))}
				</MDBox>
			</MDBox>
		</MDBox>
	);
}
