/* eslint-disable no-underscore-dangle */
import {
	Box,
	Checkbox,
	FormControl,
	FormControlLabel,
	InputLabel,
	MenuItem,
	Select,
	Table
} from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import i18n from "i18n";
import { useState } from "react";

export default function SelectProfile({
	setProfileUser,
	listProfile,
	rolesProfileUser,
	setRoleProfile,
	listAssistant,
	listOfRolesAssistant
}) {
	const [selectedRoles, setSelectedRoles] = useState({});

	const handleCheckboxChange = item => event => {
		if (event.target.checked) {
			listProfile.push(item.assistantID);
			setSelectedRoles(prevSelectedRoles => ({
				...prevSelectedRoles,
				[item.assistantID]: null
			}));
		} else {
			const indexToRemove = listProfile.indexOf(item.assistantID);
			const updatedRolesUser = rolesProfileUser.filter(entry => entry.profile !== item.assistantID);

			if (indexToRemove !== -1) {
				listProfile.splice(indexToRemove, 1);
			}

			setSelectedRoles(prevSelectedRoles => ({
				...prevSelectedRoles,
				[item.assistantID]: null
			}));

			setRoleProfile(updatedRolesUser);
		}

		setProfileUser([...listProfile]);
	};

	const handleRole = item => event => {
		const selectedRole = event.target.value;

		setSelectedRoles(prevSelectedRoles => ({
			...prevSelectedRoles,
			[item.assistantID]: selectedRole
		}));

		const selectedProfileRole = {
			profile: item.assistantID,
			name: item.name,
			role: selectedRole
		};

		const updatedRolesUser = rolesProfileUser.filter(entry => entry.profile !== item.assistantID);

		if (selectedRole) {
			updatedRolesUser.push(selectedProfileRole);
		}

		setRoleProfile(updatedRolesUser);
	};

	const setNameAssistant = (item, assistantID) => {
		if (item?.assistants) {
			const assistants = item?.assistants.find(item => item.assistantID === assistantID);
			return assistants.name;
		} else {
			return assistantID;
		}
	};

	return (
		<MDBox display="flex">
			<hr style={{ borderLeft: "1px solid #ccc", height: "100%", margin: "0 10px" }} />
			<MDBox
				display="flex"
				flexDirection="column"
				style={{
					maxHeight: "70vh",
					overflowY: "scroll"
				}}
			>
				<MDTypography variant="h6">{i18n.t("FORMS.choiceProfile")}</MDTypography>
				<Table>
					{listAssistant?.assistants?.map((item, index) => (
						<tr key={index}>
							<td
								style={{
									whiteSpace: "nowrap"
								}}
							>
								<FormControlLabel
									style={{ marginLeft: 0 }}
									key={item._id}
									control={<Checkbox />}
									label={setNameAssistant(listAssistant, item.assistantID)}
									onChange={handleCheckboxChange(item)}
								/>
							</td>
							<td>
								{!listProfile.includes(item.assistantID) && (
									<Box sx={{ margin: 1, minWidth: 85 }}></Box>
								)}
								{listProfile.includes(item.assistantID) && (
									<FormControl sx={{ m: 1, minWidth: 80 }}>
										<InputLabel id="demo-simple-select-autowidth-label">Rôle</InputLabel>
										<Select
											labelId="demo-simple-select-autowidth-label"
											id="demo-simple-select-autowidth"
											autoWidth
											label="Rôle"
											value={selectedRoles[item.assistantID] || ""}
											onChange={handleRole(item)}
											disabled={!listProfile.includes(item.assistantID)}
										>
											{listOfRolesAssistant
												.filter(role => role.assistantID === item.assistantID)
												.map(role =>
													role.roles
														.sort((a, b) => a._id.localeCompare(b._id))
														.map(item => (
															<MenuItem key={item._id} value={item._id}>
																{item._id}
															</MenuItem>
														))
												)}
										</Select>
									</FormControl>
								)}
							</td>
						</tr>
					))}
				</Table>
			</MDBox>
		</MDBox>
	);
}
