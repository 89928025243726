import { display } from "redux-react/reducers/snackBarReducer";
import { api } from "./api";

const actions = {
	insertDictionary: (data, onSuccess, onFailure) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/dictionary`,
					data,
					onSuccess,
					onFailure
				})
			);
		};
	},
	getRoles: onSuccess => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/roles`,
					onSuccess,
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error ?? "none"
							})
						);
					}
				})
			);
		};
	},
	getSettings: (type, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/settings/${type}`,
					onSuccess,
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error?.message ?? "none"
							})
						);
					}
				})
			);
		};
	},
	newSetting: (category, data, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/settings/${category}`,
					data,
					onSuccess,
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error?.message ?? "none"
							})
						);
					}
				})
			);
		};
	},
	editSetting: (category, data, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "PUT",
					url: `/api/v1/settings/${category}`,
					data,
					onSuccess,
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error?.message ?? "none"
							})
						);
					}
				})
			);
		};
	},
	deleteSetting: (category, query, onSuccess) => {
		let data = {
			query
		};
		return dispatch => {
			dispatch(
				api({
					method: "DELETE",
					url: `/api/v1/settings/${category}`,
					data,
					onSuccess,
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error?.message ?? "none"
							})
						);
					}
				})
			);
		};
	},
	reorderPage: (datas, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/settings/page/${datas.from}/${datas.to}`,
					onSuccess,
					data: {},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: "rrr"
							})
						);
					}
				})
			);
		};
	}
};

export default actions;
