/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";

export function ComplexStatisticsCard({ title, count, percentage, icon }) {
	return (
		<Card>
			<MDBox display="flex" flexDirection="column" justifyContent="space-between" pt={1} px={2}>
				{icon?.component && (
					<MDBox
						bgColor={icon?.color || "info"}
						variant="gradient"
						coloredShadow={icon?.color || "info"}
						borderRadius="xl"
						display="flex"
						justifyContent="center"
						alignItems="center"
						width="3rem"
						height="3rem"
						color="white"
						mt={-3}
					>
						<Icon fontSize="small" color="inherit">
							{icon?.component}
						</Icon>
					</MDBox>
				)}
				<MDBox textAlign="right" lineHeight={1.25}>
					<MDTypography variant="button" fontWeight="light" color="text">
						{title}
					</MDTypography>
					<MDTypography variant="h4">{count}</MDTypography>
				</MDBox>
			</MDBox>
			<Divider />
			<MDBox pb={2} px={2}>
				{percentage && (
					<MDTypography component="p" variant="button" color="text" display="flex">
						<MDTypography
							component="span"
							variant="button"
							fontWeight="bold"
							color={percentage.color}
						>
							{percentage.amount}
						</MDTypography>
						&nbsp;{percentage.label}
					</MDTypography>
				)}
			</MDBox>
		</Card>
	);
}

export function LoadingComplexStatisticsCard({ title, icon, text }) {
	return (
		<Card>
			<MDBox display="flex" flexDirection="column" justifyContent="space-between" pt={1} px={2}>
				{icon?.component && (
					<MDBox
						bgColor={icon?.color || "info"}
						variant="gradient"
						coloredShadow={icon?.color || "info"}
						borderRadius="xl"
						display="flex"
						justifyContent="center"
						alignItems="center"
						width="3rem"
						height="3rem"
						color="white"
						mt={-3}
					>
						<Icon fontSize="small" color="inherit">
							{icon?.component}
						</Icon>
					</MDBox>
				)}
				<MDBox textAlign="right" lineHeight={1.25}>
					<MDTypography variant="button" fontWeight="light" color="text">
						{title}
					</MDTypography>
					<MDTypography variant="h4">{text}</MDTypography>
				</MDBox>
			</MDBox>
			<Divider />
			<MDBox pb={2} px={2}></MDBox>
		</Card>
	);
}
