import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	open: false,
	message: "",
	type: "info"
};

export const snackBar = initialState;

const slice = createSlice({
	name: "snackBar",
	initialState,
	reducers: {
		display: (state, action) => {
			let { message, type } = action.payload;
			state.message = message;
			state.type = type;
			state.open = true;
		},
		hide: state => {
			state.open = false;
		}
	}
});

export const selectSnackBar = state => state.snackBar;

export const { display, hide } = slice.actions;

export default slice.reducer;
