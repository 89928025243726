/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */

import { useState } from "react";
import { useSelector } from "react-redux";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import MDButton from "components/Basics/MDButton";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import i18n from "i18n";
import { Card, CardContent, Checkbox, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import lod_ from "lodash";

// Material Dashboard 2 PRO React components
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";

// Material Dashboard 2 PRO React examples
import DataTableHeadCell from "./DataTableHeadCell";
import DataTableBodyCell from "./DataTableBodyCell";

/**
 * ##############################
 * Handle specials actions in props
 * -> allow to custom actions with row values
 * e.g : disabe, click, loadingn style
 * ##############################
 */
function overrideObjectProps(action, row) {
	// custom disabled method
	const checkDisabled = act => {
		if (act.props?.disabled) {
			return act.props.disabled;
		} else if (act.props?.customdisabled) {
			return act.props.customdisabled(row);
		} else {
			return false;
		}
	};
	// custom style
	const customStyle = act => {
		let style = act.props?.style ?? {};
		if (act.props?.customstyle) {
			style = act.props.customstyle(row, style);
		}
		return style;
	};

	const customLoading = act => {
		if (act.props?.loading) {
			let loadingObject = act.props.loading(row);
			if (loadingObject.loading) {
				return loadingObject.component;
			}
		}
		return act.props?.children;
	};

	action = {
		...action,
		props: {
			...action.props,
			onClick(e) {
				action.props?.handleclick ? action.props?.handleclick(row, e) : null;
			},
			disabled: checkDisabled(action),
			style: customStyle(action),
			children: customLoading(action)
		}
	};

	if (action.props.children && lod_.isObject(action.props.children)) {
		action.props.children = overrideObjectProps(action.props.children, row);
	}

	return action;
}

const Row = ({
	dictionaryCode,
	index,
	object,
	display,
	layer,
	editionMode,
	actions,
	dictionaryFormat,
	handleAddRow
}) => {
	const [open, setOpen] = useState(false);
	const [menu, setMenu] = useState(null);
	const { user } = useSelector(state => state);

	const openMenu = e => {
		setMenu(e.currentTarget);
		e.stopPropagation();
	};

	const closeMenu = () => setMenu(null);

	const handleClickMenuItem = datas => {
		handleAddRow(datas);
		closeMenu();
	};

	const renderAddMenu = () => {
		return (
			<Menu
				className="addMenu"
				anchorEl={menu}
				open={Boolean(menu)}
				onClose={closeMenu}
				anchorOrigin={{
					vertical: "center",
					horizontal: "right"
				}}
				transformOrigin={{
					vertical: "center",
					horizontal: "left"
				}}
			>
				{/* Add item in the object, parent is the row */}
				{object.type === "level" && (
					<MenuItem
						onClick={() => {
							let datas = {
								codeDictionary: dictionaryCode,
								parent: object._id,
								order: object.items ? Object.keys(object.items).length : 0,
								active: true
							};
							handleClickMenuItem(datas);
						}}
					>
						<Icon>subdirectory_arrow_right</Icon>
						<MDBox
							sx={{
								ml: 1
							}}
						>
							<MDTypography variant="button" fontWeight="regular">{`${i18n.t(
								"SETTINGS.DICTIONARY.addIn"
							)} ${object?.label?.fr}`}</MDTypography>
						</MDBox>
					</MenuItem>
				)}
				{/* Add item after this row, parent is the same, only for layer > 1 */}
				{layer > 1 && (
					<MenuItem
						onClick={() => {
							let datas = {
								codeDictionary: dictionaryCode,
								parent: object.parent,
								order: object.order + 1,
								active: true
							};
							handleClickMenuItem(datas);
						}}
					>
						<Icon>add</Icon>
						<MDBox
							sx={{
								ml: 1
							}}
						>
							<MDTypography variant="button" fontWeight="regular">{`${i18n.t(
								"SETTINGS.DICTIONARY.addAfter"
							)} ${object?.label?.fr}`}</MDTypography>
						</MDBox>
					</MenuItem>
				)}
			</Menu>
		);
	};

	return (
		<>
			{/* Add menu */}
			{renderAddMenu()}
			{/* Main component */}
			<Draggable isDragDisabled index={index} draggableId={`${index}`}>
				{provided => (
					<TableRow
						ref={provided.innerRef}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						onClick={() => {
							setOpen(!open);
						}}
						className={object.items ? "dictionaryRowClickable dictionaryRow" : "dictionaryRow"}
					>
						{/* Add row */}
						{editionMode && (
							<DataTableBodyCell>
								<Tooltip placement="top" title={i18n.t("SETTINGS.DICTIONARY.addAField")}>
									<IconButton onClick={openMenu}>
										<Icon fontSize="medium">add</Icon>
									</IconButton>
								</Tooltip>
							</DataTableBodyCell>
						)}
						{/* Icons & name */}
						<DataTableBodyCell
							style={{
								paddingLeft: layer * 2 + "rem"
							}}
						>
							<MDBox display="flex" flexDirection="row" alignItems="center">
								<MDBox display="flex" flexDirection="row" alignItems="center">
									{object.type === "level" &&
										(open ? (
											<Icon fontSize="medium">keyboard_arrow_down</Icon>
										) : (
											<Icon fontSize="medium">keyboard_arrow_right</Icon>
										))}
									{object.type !== "level" &&
										(object.system ? (
											<Tooltip placement="top" title="Donnée système">
												<Icon fontSize="medium">admin_panel_settings</Icon>
											</Tooltip>
										) : (
											<Icon fontSize="medium">article</Icon>
										))}
								</MDBox>
								<MDBox sx={{ pl: 2 }}>{object?.label?.fr}</MDBox>
							</MDBox>
						</DataTableBodyCell>
						{/* All columns */}
						{display.map((column, index) => {
							/* Code */
							if (column === "code") return null;
							/* Boolean */
							let dictionaryItem = dictionaryFormat?.items[column];
							if (dictionaryItem && dictionaryItem.type === "boolean") {
								return (
									<DataTableBodyCell key={index}>
										<Checkbox disabled checked={lod_.get(object, column)} />
									</DataTableBodyCell>
								);
							}
							/* Objects */
							if (lod_.isObject(lod_.get(object, column))) {
								return (
									<DataTableBodyCell key={index}>
										<table>
											{Object.keys(lod_.get(object, column)).map((key, index) => {
												return (
													<tr key={index}>
														<td className="listKeyStyle">{key}</td>
														<td className="grid-item">{lod_.get(object, column)[key]}</td>
													</tr>
												);
											})}
										</table>
									</DataTableBodyCell>
								);
							}
							/* Default */
							return <DataTableBodyCell key={index}>{lod_.get(object, column)}</DataTableBodyCell>;
						})}
						{/* Actions */}
						{editionMode && (
							<DataTableBodyCell>
								{object.system !== true || user?.system === true ? (
									<MDBox display="flex" justifyContent="flex-end">
										{actions.map((action, index) => {
											let overrideAction = overrideObjectProps(action, object);
											return <span key={index}>{overrideAction}</span>;
										})}
									</MDBox>
								) : null}
							</DataTableBodyCell>
						)}
					</TableRow>
				)}
			</Draggable>
			{/* Map items */}
			{object.items && open && (
				<DictionaryContent
					dictionaryCode={dictionaryCode}
					dictionary={object.items}
					display={display}
					layer={layer + 1}
					editionMode={editionMode}
					actions={actions}
					dictionaryFormat={dictionaryFormat}
					handleAddRow={handleAddRow}
				/>
			)}
		</>
	);
};

const DictionaryContent = ({
	dictionaryCode,
	dictionaryFormat,
	dictionary,
	display,
	layer = 1,
	editionMode,
	actions,
	handleAddRow
}) => {
	if (lod_.isEmpty(dictionary)) {
		return (
			<TableRow>
				<td colSpan={display.length + 2}>
					{editionMode && layer === 1 ? (
						<MDBox display="flex" justifyContent="center" mt={2}>
							<MDButton
								size="small"
								display="flex"
								color="info"
								onClick={() => {
									handleAddRow({
										codeDictionary: dictionaryCode,
										parent: "root",
										order: 0,
										active: true
									});
								}}
							>
								<Icon>add</Icon>&nbsp;{i18n.t("SETTINGS.add")}
							</MDButton>
						</MDBox>
					) : (
						<MDBox display="flex" justifyContent="center" mt={2}>
							<MDTypography variant="caption">{i18n.t("SETTINGS.DICTIONARY.noData")}</MDTypography>
						</MDBox>
					)}
				</td>
			</TableRow>
		);
	}

	return Object.keys(dictionary).map((key, index) => {
		let array = [];
		let value = dictionary[key];

		array.push(
			<Row
				key={index}
				dictionaryCode={dictionaryCode}
				index={index}
				object={value}
				display={display}
				layer={layer}
				editionMode={editionMode}
				actions={actions}
				dictionaryFormat={dictionaryFormat}
				handleAddRow={handleAddRow}
			/>
		);
		return array;
	});
};

function DictionaryDataTable({
	display,
	dictionaryCode,
	dictionary,
	title,

	actions = [],
	dictionaryFormat,

	handleAddRow
}) {
	const [editionMode, setEditionMode] = useState(false);
	return (
		<Card>
			<CardContent>
				<MDBox
					display="flex"
					flexDirection="row"
					alignItems="center"
					justifyContent="space-between"
					mb={2}
				>
					<MDBox>
						<MDTypography variant="h6">{title}</MDTypography>
					</MDBox>
					<MDBox>
						{/* copy to clipboard object dictionary */}
						<MDBox display="flex" alignItems="center">
							<Tooltip
								placement="top"
								title={i18n.t("SETTINGS.DICTIONARY.copyToClipboard")}
								onClick={() => navigator.clipboard.writeText(JSON.stringify(dictionary))}
							>
								<IconButton>
									<Icon>content_copy</Icon>
								</IconButton>
							</Tooltip>
							<Tooltip
								placement="top"
								title={
									!editionMode
										? i18n.t("SETTINGS.DICTIONARY.unlockEdition")
										: i18n.t("SETTINGS.DICTIONARY.lockEdition")
								}
							>
								<IconButton onClick={() => setEditionMode(!editionMode)}>
									{!editionMode ? <Icon>edit</Icon> : <Icon>edit_off</Icon>}
								</IconButton>
							</Tooltip>
						</MDBox>
					</MDBox>
				</MDBox>
				<TableContainer sx={{ boxShadow: "none" }}>
					<DragDropContext>
						<Table>
							<TableRow>
								{/* Add row */}
								{editionMode && <DataTableHeadCell align="left" sorted={false}></DataTableHeadCell>}
								{/* Empty cell for icons & code */}
								<DataTableHeadCell align="right" sorted={false}></DataTableHeadCell>
								{/* Map all other rows */}
								{display.map((column, index) => {
									if (column === "code") return null;
									return (
										<DataTableHeadCell key={index} align="left" sorted={false}>
											{column !== "code" ? column : null}
										</DataTableHeadCell>
									);
								})}
								{/* Actions */}
								{editionMode && (
									<DataTableHeadCell width={20} align="right" sorted={false}></DataTableHeadCell>
								)}
							</TableRow>
							<Droppable droppableId="dnd-list" direction="vertical">
								{provided => (
									<TableBody {...provided.droppableProps} ref={provided.innerRef}>
										<DictionaryContent
											dictionaryCode={dictionaryCode}
											dictionary={dictionary}
											dictionaryFormat={dictionaryFormat}
											display={display}
											editionMode={editionMode}
											actions={actions}
											handleAddRow={handleAddRow}
										/>
										{provided.placeholder}
									</TableBody>
								)}
							</Droppable>
						</Table>
					</DragDropContext>
				</TableContainer>
			</CardContent>
		</Card>
	);
}
export default DictionaryDataTable;
