/* eslint-disable no-restricted-syntax */
import { useState, useEffect } from "react";

import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import Configurator from "components/Advanced/Configurator";

import theme from "assets/theme";
import themeDark from "assets/theme-dark";

import Router from "routes/routes";

import { useMaterialUIController, setMiniSidenav } from "context";

import lod_ from "lodash";

import myLogo from "assets/images/logo.png";

import "assets/style/app.css";
import "assets/style/error.css";
import "assets/style/navbar.css";
import { useSelector } from "react-redux";

import "./assets/fonts/Cedora-Bold.otf";
import "./assets/fonts/Cedora-Regular.otf";
import SnackBarMessage from "components/Custom/SnackBar";
import { buildCustomRoutes } from "routes/utils";
import Sidenav from "components/Advanced/Sidenav";

/**
 * @param {*} permissionsExpected The permissions expected to access the element
 * @param {*} permissionsGiven The permissions of the user
 * @returns
 */
export const hasPermissions = (permissionsExpected, permissionsGiven) => {
	if (!permissionsExpected || lod_.isEmpty(permissionsExpected)) return true;

	let hasPermission = false;

	for (let perm of permissionsExpected) {
		if (permissionsGiven.includes(perm)) {
			hasPermission = true;
			break;
		}
	}

	return hasPermission;
};

export default function App() {
	const [controller, dispatch] = useMaterialUIController();
	const { miniSidenav, darkMode } = controller;
	const [onMouseEnter, setOnMouseEnter] = useState(false);
	const { pathname } = useLocation();
	const { profile, user, permissions } = useSelector(state => state);
	const [routes, setRoutes] = useState([]);

	const brandName = user.accountName.toUpperCase();

	// Open sidenav when mouse enter on mini sidenav
	const handleOnMouseEnter = () => {
		if (miniSidenav && !onMouseEnter) {
			setMiniSidenav(dispatch, false);
			setOnMouseEnter(true);
		}
	};

	// Close sidenav when mouse leave mini sidenav
	const handleOnMouseLeave = () => {
		if (onMouseEnter) {
			setMiniSidenav(dispatch, true);
			setOnMouseEnter(false);
		}
	};

	/**
	 * Get all routes
	 */
	const getRoutes = allRoutes => {
		let routes = allRoutes.map((route, index) => {
			if (route.permissions) {
				if (!hasPermissions(route.permissions, permissions.permissions)) {
					return null;
				}
			}

			if (route.collapse) {
				return getRoutes(route.collapse);
			}

			if (route.route && route.type !== "hidden") {
				return <Route exact path={route.route} element={route.component} key={index} />;
			}

			return null;
		});

		let redirectRoute = allRoutes.find(
			route => Boolean(route.route) && !["/login", "/logout"].includes(route.route)
		);

		if (redirectRoute) {
			routes.push();
		}

		return routes;
	};

	/**
	 * Get the redirect route
	 */
	function getRedirectRoute(routes) {
		let redirectRoute = routes.find(
			route => Boolean(route.route) && !["/login", "/logout"].includes(route.route)
		);

		if (redirectRoute) {
			return <Route path="*" element={<Navigate to={redirectRoute.route} />} />;
		}

		return null;
	}

	/**
	 * If user is logged, he needs to chose a profile before to be redirect to dashboard
	 */
	function router() {
		return (
			<>
				{getRoutes(routes)}
				{getRedirectRoute(routes)}
			</>
		);
	}

	/**
	 * Merge basic routes and custom routes
	 */
	useEffect(() => {
		if (!lod_.isEmpty(profile)) {
			let basicRoutes = Router();
			let customRoutes = profile?.selectedAssistant?.menu ?? [];
			let newRoutes = [...basicRoutes, ...customRoutes];
			newRoutes = buildCustomRoutes(newRoutes);
			setRoutes(newRoutes);
		}
	}, [profile]);

	/**
	 * Scroll to top of the page when changing the route
	 */
	useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
	}, [pathname]);

	return (
		<ThemeProvider theme={darkMode ? themeDark : theme}>
			<CssBaseline />
			<>
				<SnackBarMessage />
				<Sidenav
					brandName={brandName}
					brand={myLogo}
					routes={routes}
					onMouseEnter={handleOnMouseEnter}
					onMouseLeave={handleOnMouseLeave}
				/>
				<Configurator />
			</>
			<Routes>{router()}</Routes>
		</ThemeProvider>
	);
}
