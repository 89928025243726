import { Typography } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import TagsList from "components/Custom/TagList";
import i18n from "i18n";
import { useEffect, useState } from "react";

export default function SkillsChoice({ listSkills, child, label, value, onChangeList, disabled }) {
	const [selected, setSelected] = useState(value);

	useEffect(() => {
		onChangeList(selected);
	}, [selected]);

	return (
		<MDBox>
			<Typography mt={2}>{i18n.t("FORMS.choiceSkill")}</Typography>
			<TagsList
				list={listSkills}
				labelField="name"
				titleField="description"
				valueField="code"
				isForm
				selected={selected}
				setSelected={setSelected}
				isIntent
				disabled={disabled}
			/>
		</MDBox>
	);
}
