import { Switch } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import { useEffect, useState } from "react";

export default function BooleanInput({ value, PI, label, onChange }) {
	const [checked, setChecked] = useState(Boolean(value));

	const handleCheck = e => {
		if (PI) {
			setChecked(!checked);
			onChange(!checked);
		}
	};

	// init value for first render
	useEffect(() => {
		onChange(checked);
	}, []);

	return (
		<MDBox
			mt={1}
			display="flex"
			justifyContent="space-between"
			alignItems="center"
			className="customSwitchContainer"
			onClick={e => handleCheck(e)}
		>
			<span>{label}</span>
			<MDBox>
				<Switch checked={checked} />
			</MDBox>
		</MDBox>
	);
}
