/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
/**
 * #######################################################@
 *
 * FORMS page
 *
 * #######################################################@
 */
import "./style.css";

import {
	Box,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Fade,
	Icon,
	IconButton,
	Tooltip
} from "@mui/material";
import FormActions from "redux-react/actions/formAction";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { display } from "redux-react/reducers/snackBarReducer";
import MDButton from "components/Basics/MDButton";
import MDBox from "components/Basics/MDBox";
import i18n from "i18n";
import lod_ from "lodash";
import BlockIcon from "@mui/icons-material/Block";
import EditIcon from "@mui/icons-material/Edit";
import SyncLockIcon from "@mui/icons-material/SyncLock";
import DoDisturbOffIcon from "@mui/icons-material/DoDisturbOff";
import { useForm } from "hooks/useForm";
import { regexPhoneNumber, regexMail } from "helpers/utilities";
import { getSignedUrlS3, uploadToSignedUrl, removeToSignedUrl } from "helpers/s3";
import MDTypography from "components/Basics/MDTypography";
import SelectProfile from "./components/RightPannel/SelectProfiles";
import FormLayerObject from "./components/Layer/FormLayerObject";
import DictionaryCode from "./components/RightPannel/DictionaryCode";
import DictionaryDynamic from "./components/RightPannel/DictionaryDynamic";

export default function FormDictionaryDialog({
	route,
	open,
	handleCloseDialog,
	handleSave,
	valuesDictionary,
	formBuildDictionary,
	contextDictionary,
	PIaccess,
	isEmpty,
	target,
	selectedId,
	routeDict,
	flagAccount,
	forceEdit = false,
	regexEmail,
	listAPIs,
	reloadTable
}) {
	let validate = () => {
		const errors = {};
		return errors;
	};

	const [PI, setPI] = useState(PIaccess);
	const [formDictionary, setFormDictionary] = useState(formBuildDictionary);
	const [customErrors, setCustomErrors] = useState({});
	const [hasErrors, setHasErrors] = useState(false);
	const [confirmHasPI, setConfirmHasPI] = useState(false);
	const dispatch = useDispatch();
	const [customUnique, setCustomUnique] = useState({});
	const [loadingValidate, setLoadingValidate] = useState(false);
	const [customeProfile, setCustomeProfile] = useState([]);
	const [profileUser, setProfileUser] = useState([]);
	const [rolesProfileUser, setRolesProfileUser] = useState([]);
	const [assistants, setAssistants] = useState([]);
	const [selectedUser, setSelectedUser] = useState({});
	const [blockUserSelected, setBlockUserSelected] = useState(false);
	const [listOfRoles, setListOfRoles] = useState([]);
	const [listOfRolesAssistant, setListOfRolesAssistant] = useState([]);
	const [listSkills, setListSkills] = useState([]);
	const [stringCode, setStringCode] = useState("");
	const [stringLabel, setStringLabel] = useState("");
	const [stringWarnAdd, setStringWarnAdd] = useState("");
	const [stringWarnCode, setStringWarnCode] = useState("");
	const [showWarning, setShowWarning] = useState(false);
	const [showWarningCode, setShowWarningCode] = useState(false);
	const [showWarningLabel, setShowWarningLabel] = useState(false);
	const [indexWarnCode, setIndexWarnCode] = useState(null);

	const [stringWarnAddWD, setStringWarnAddWD] = useState("");
	const [showWarningWD, setShowWarningWD] = useState(false);

	const { user } = useSelector(state => state);
	const { permissions } = useSelector(state => state);

	let { values, errors, setValues, handleInputChange } = useForm(valuesDictionary, true, validate);

	const listOfRoleAssistantP = item => {
		const onSuccessRoles = res => {
			setListOfRolesAssistant(res.rolesAssistants);
		};

		dispatch(FormActions.getRolesAssistantP(item, onSuccessRoles));
	};

	/**
	 * Check if there is errors in form to enable/disable validate button
	 */
	useEffect(() => {
		if (
			!lod_.isEmpty(customErrors) ||
			(lod_.isEmpty(rolesProfileUser) && target === "user" && isEmpty)
		) {
			setHasErrors(true);
		} else {
			setHasErrors(false);
		}
	}, [customErrors, rolesProfileUser]);

	function setListNotEmpty() {
		if (routeDict === "dictionary" && isEmpty) {
			return [];
		} else if (routeDict === "dictionary" && lod_.isArray(values?.codes) && !isEmpty) {
			return values?.codes;
		} else {
			return [];
		}
	}

	const [listCodes, setListCodes] = useState(setListNotEmpty());

	/**
	 * Setup form with get different config/info for route target
	 */
	useEffect(() => {
		const onSuccessProfile = res => {
			setCustomeProfile(res);
		};

		const onSuccessAssitant = res => {
			setAssistants(res);
			listOfRoleAssistantP(res);
		};

		const onSuccessUser = res => {
			setSelectedUser(res.item);
			setBlockUserSelected(res.item?.block || false);
		};

		const onSuccessRoles = res => {
			setListOfRoles(res.roles.roleDetail);
		};

		if (target === "user") {
			dispatch(FormActions.getProfileItem(user.userID, "user", onSuccessProfile));
			if (!isEmpty) {
				dispatch(FormActions.getItemByID(selectedId, target, routeDict, onSuccessUser, true, true));
			}
			dispatch(FormActions.getRolesP(onSuccessRoles));

			dispatch(FormActions.getAssistants("assistant", onSuccessAssitant));
		}

		if (target === "profile") {
			dispatch(FormActions.getRolesP(onSuccessRoles));
			dispatch(FormActions.getAssistants("assistant", onSuccessAssitant));
		}
	}, [route]);

	/**
	 * Change value with different condition and sand error if not agree with condition
	 */
	useEffect(() => {
		if (routeDict === "dictionary") {
			let e = { target: { name: "whitelist", value: [] } };
			handleInputChange(e);
			if (["boolean", "code", "level", "color"].includes(values?.type)) {
				let cloneValues = lod_.cloneDeep(values);
				cloneValues.isDynamic = false;
				delete cloneValues?.whitelistDynamic;
				setValues(cloneValues);
			}

			let e2 = { target: { name: "isDynamic", value: false } };
			handleInputChange(e2);

			if (["labels"].includes(values?.type)) {
				let cloneValues = lod_.cloneDeep(values);
				lod_.set(cloneValues, "isDynamic", true);
				lod_.set(cloneValues, "whitelistDynamic", {});
				setValues(cloneValues);
			}
		}
	}, [values?.type]);

	useEffect(() => {
		if (routeDict === "dictionary") {
			let cloneValues = lod_.cloneDeep(values);

			if (values.isDynamic === false) {
				lod_.set(cloneValues, "whitelistDynamic", {});
			}
			setValues(cloneValues);
		}
	}, [values?.isDynamic]);

	const handleInputChangeValidate = async (e, value, child) => {
		// special handle for default value of dictionary

		if (child === "defaultValue" && routeDict === "dictionary") {
			value.type = e.defaultType;
			e = e.event;
		}

		if (value?.type === "boolean") {
			e = { target: { name: child, value: Boolean(e) } };
			handleInputChange(e);
			return;
		}

		if (child.includes(".value")) {
			child = child.replaceAll(".value", "");
			e.target.name = e.target.name.replaceAll(".value", "");
		}

		if (value?.blacklist) {
			if (value.blacklist.includes(e.target.value)) {
				setCustomErrors({ ...customErrors, [child]: i18n.t("FORMS.blackListCustomer") });
				setHasErrors(true);
			} else {
				let copyErrors = lod_.cloneDeep(customErrors);
				delete copyErrors[child];
				setCustomErrors(copyErrors);
				setHasErrors(false);
			}
		}

		if (value?.whitelist) {
			if (
				value.whitelist.includes(e.target.value) ||
				e.target.value === "" ||
				value.whitelist.length === 0
			) {
				let copyErrors = lod_.cloneDeep(customErrors);
				delete copyErrors[child];
				setCustomErrors(copyErrors);
				setHasErrors(false);
			} else {
				setCustomErrors({ ...customErrors, [child]: i18n.t("FORMS.whitelistContact") });
				setHasErrors(true);
			}
		}

		if (value?.unique) {
			if (e.target.value !== value.value) {
				setCustomUnique({ ...customUnique, [child]: e.target.value });
			} else {
				let copyUnique = lod_.cloneDeep(customUnique);
				delete copyUnique[child];
				setCustomUnique(copyUnique);
			}

			if (value?.type === "email" && !regexMail(e.target.value, regexEmail) && !value?.isArray) {
				setCustomErrors({
					...customErrors,
					[child]: i18n.t("FORMS.invalidEmail")
				});
			} else {
				let copyErrors = lod_.cloneDeep(customErrors);
				delete copyErrors[child];
				setCustomErrors(copyErrors);
			}
		} else if (
			value?.type === "email" &&
			!value?.isArray &&
			!regexMail(e.target.value, regexEmail)
		) {
			setCustomErrors({
				...customErrors,
				[child]: i18n.t("FORMS.invalidEmail")
			});
		} else {
			let copyErrors = lod_.cloneDeep(customErrors);
			delete copyErrors[child];
			setCustomErrors(copyErrors);
		}

		if (value?.type === "mdp") {
			const password = e.target.value;

			const AtLeast8chars = password.length > 7;

			const AtLeastOneLower = /[a-z]/.test(password);

			const AtLeastOneUpper = /[A-Z]/.test(password);

			const AtLeastOneNumber = /[0-9]/.test(password);

			const AtLeastOneSpecial = /[-+_!@#$%^&*.,?]/.test(password);

			const passwordIsStrong =
				AtLeast8chars &&
				AtLeastOneLower &&
				AtLeastOneUpper &&
				AtLeastOneNumber &&
				AtLeastOneSpecial;

			const lengthValue = i18n.t("FORMS.psswordStrength.length");
			const oneLower = i18n.t("FORMS.psswordStrength.lowercaseLetter");
			const oneUpper = i18n.t("FORMS.psswordStrength.capitalLetter");
			const oneNumber = i18n.t("FORMS.psswordStrength.number");
			const oneSpecial = i18n.t("FORMS.psswordStrength.specialChar");

			const validateMessage = (
				<MDBox borderRadius="lg" p={3}>
					<MDBox>
						<MDTypography display="inline" variant="h6" fontWeight="bold">
							{i18n.t("FORMS.psswordStrength.message")}
						</MDTypography>
						<MDBox>
							<MDTypography
								variant="body2"
								component="p"
								alignItems="center"
								display="flex"
								style={{ color: !AtLeast8chars ? "red" : "green" }}
							>
								{lengthValue} {!AtLeast8chars ? <Icon>close </Icon> : <Icon>check </Icon>}
							</MDTypography>

							<MDTypography
								variant="body2"
								component="p"
								alignItems="center"
								display="flex"
								style={{ color: !AtLeastOneLower ? "red" : "green" }}
							>
								{oneLower} {!AtLeastOneLower ? <Icon>close </Icon> : <Icon>check </Icon>}
							</MDTypography>

							<MDTypography
								variant="body2"
								component="p"
								style={{ color: !AtLeastOneUpper ? "red" : "green" }}
								alignItems="center"
								display="flex"
							>
								{oneUpper} {!AtLeastOneUpper ? <Icon>close </Icon> : <Icon>check </Icon>}
							</MDTypography>

							<MDTypography
								variant="body2"
								component="p"
								alignItems="center"
								display="flex"
								style={{ color: !AtLeastOneNumber ? "red" : "green" }}
							>
								{oneNumber} {!AtLeastOneNumber ? <Icon>close </Icon> : <Icon>check </Icon>}
							</MDTypography>
							<MDTypography
								variant="body2"
								component="p"
								alignItems="center"
								display="flex"
								style={{ color: !AtLeastOneSpecial ? "red" : "green" }}
							>
								{oneSpecial} {!AtLeastOneSpecial ? <Icon>close </Icon> : <Icon>check </Icon>}
							</MDTypography>
						</MDBox>
					</MDBox>
				</MDBox>
			);

			if (!passwordIsStrong) {
				setCustomErrors({ ...customErrors, [child]: validateMessage });
				setHasErrors(true);
			} else {
				let copyErrors = lod_.cloneDeep(customErrors);
				delete copyErrors[child];
				setCustomErrors(copyErrors);
				setHasErrors(false);
			}
		}

		if (value?.type === "date") {
			e = { target: { name: child, value: new Date(e[0]) } };
		}

		if (value?.type === "number") {
			if (!value?.isArray) {
				e = { target: { name: child, value: parseFloat(e.target.value) } };
			}
		}

		if (value?.type === "picture") {
			if (e.type === "change") {
				if (e.target.files[0].type.startsWith("image/")) {
					const result = await getSignedUrlS3(e.target.files[0]);

					await uploadToSignedUrl(result.signedURL, e.target.files[0]);

					e = {
						target: {
							name: child,
							value: result.accessURL
						}
					};
					let copyErrors = lod_.cloneDeep(customErrors);
					delete copyErrors[child];
					setCustomErrors(copyErrors);
				} else {
					setCustomErrors({
						...customErrors,
						[child]: i18n.t("FORMS.invalidPicture")
					});
					e = { target: { name: child, value: "" } };
				}
			} else {
				await removeToSignedUrl(values[child]);
				e = { target: { name: child, value: "" } };
			}
		}

		if (value?.type === "phone") {
			e = { target: { name: child, value: e } };
			e.target.value = e.target.value.replace(/\s/g, "");
			if (!regexPhoneNumber(e.target.value) && !lod_.isEmpty(e.target.value)) {
				setCustomErrors({ ...customErrors, [child]: i18n.t("FORMS.invalidPhone") });
			} else {
				let copyErrors = lod_.cloneDeep(customErrors);
				delete copyErrors[child];
				setCustomErrors(copyErrors);
			}
		}

		handleInputChange(e);
	};

	const close = (e, reason) => {
		handleCloseDialog();
	};

	useEffect(() => {
		if (routeDict === "dictionary" && (values?.type === "code" || values?.type === "codeImage")) {
			let cloneValues = lod_.cloneDeep(values);
			if (!lod_.isEmpty(listCodes)) {
				cloneValues.codes = listCodes;
			} else {
				cloneValues.codes = {};
			}
			setValues(cloneValues);
		}
	}, [listCodes]);

	function checkUser(user) {
		let foundNullOrEmpty = false;
		let labelRequired = {};
		if (isEmpty) {
			lod_.forEach(user, (value, key) => {
				if (!["picture", "block"].includes(key) && (value === null || value === "")) {
					if (key === "password") {
						const lengthValue = i18n.t("FORMS.psswordStrength.length");
						const oneLower = i18n.t("FORMS.psswordStrength.lowercaseLetter");
						const oneUpper = i18n.t("FORMS.psswordStrength.capitalLetter");
						const oneNumber = i18n.t("FORMS.psswordStrength.number");
						const oneSpecial = i18n.t("FORMS.psswordStrength.specialChar");
						const validateMessage = (
							<MDBox borderRadius="lg" p={3}>
								<MDBox>
									<MDTypography display="inline" variant="h6" fontWeight="bold">
										{i18n.t("FORMS.psswordStrength.message")}
									</MDTypography>
									<MDBox>
										<MDTypography
											variant="body2"
											component="p"
											alignItems="center"
											display="flex"
											style={{ color: "red" }}
										>
											{lengthValue} <Icon>close </Icon>
										</MDTypography>

										<MDTypography
											variant="body2"
											component="p"
											alignItems="center"
											display="flex"
											style={{ color: "red" }}
										>
											{oneLower} <Icon>close </Icon>
										</MDTypography>

										<MDTypography
											variant="body2"
											component="p"
											style={{ color: "red" }}
											alignItems="center"
											display="flex"
										>
											{oneUpper} <Icon>close </Icon>
										</MDTypography>

										<MDTypography
											variant="body2"
											component="p"
											alignItems="center"
											display="flex"
											style={{ color: "red" }}
										>
											{oneNumber} <Icon>close </Icon>
										</MDTypography>
										<MDTypography
											variant="body2"
											component="p"
											alignItems="center"
											display="flex"
											style={{ color: "red" }}
										>
											{oneSpecial} <Icon>close </Icon>
										</MDTypography>
									</MDBox>
								</MDBox>
							</MDBox>
						);
						labelRequired[key] = validateMessage;
					} else {
						labelRequired[key] = i18n.t("FORMS.isRequired");
					}
					foundNullOrEmpty = true;
				}
			});
		} else {
			lod_.forEach(user, (value, key) => {
				if (!["picture", "block", "password"].includes(key) && (value === null || value === "")) {
					labelRequired[key] = i18n.t("FORMS.isRequired");
					foundNullOrEmpty = true;
				}
			});
		}
		setCustomErrors({ ...customErrors, ...labelRequired });

		return foundNullOrEmpty;
	}

	/**
	 * Send form
	 */
	const sumbit = () => {
		if (target === "user" && checkUser(values)) {
			let info = {
				message: isEmpty ? i18n.t("FORMS.noEmptyAddUser") : i18n.t("FORMS.noEmptyUpdateUser"),
				type: "warning"
			};
			dispatch(display(info));
			return;
		}

		setLoadingValidate(true);
		handleSave(
			values,
			customUnique,
			callback => {
				setLoadingValidate(false);
			},
			rolesProfileUser
		);
	};

	const convertToDefEventParam = (name, value) => ({
		target: {
			name,
			value
		}
	});

	/**
	 * Gestion remove for array in form
	 */
	const handleRemoveProduct = (index, path, e, pathDict) => {
		e.stopPropagation();
		lod_.get(values, pathDict).splice(index, 1);
		const cloneDict = lod_.cloneDeep(formDictionary);
		lod_.get(cloneDict, path).splice(index, 1);
		setFormDictionary(cloneDict);
		handleInputChange(convertToDefEventParam(path, lod_.get(values, path)));
	};

	/**
	 * Gestion add for array in form
	 */
	const handleAddProduct = (path, pathDict) => {
		let formatDict = contextDictionary;
		let pathFormat = path;
		let pathDirect = path;
		const cloneDict = lod_.cloneDeep(formDictionary);

		if (pathFormat.includes(".value")) {
			pathFormat = pathFormat.replaceAll(".value", "");
		}

		if (pathFormat.includes(".")) {
			pathFormat = pathFormat.replaceAll(".", ".items.") + ".items";
		} else {
			pathFormat += ".items";
		}
		const newFormat =
			lod_.get(formatDict, pathFormat) || lod_.get(formatDict, pathDirect + ".items");

		let valueFormat = {};
		let dictFormat = {};
		for (let childFormat in newFormat) {
			if (newFormat[childFormat].active) {
				dictFormat[childFormat] = {
					type: newFormat[childFormat].type,
					display: newFormat[childFormat].display,
					isEdit: newFormat[childFormat]?.actions?.edit || true,
					...newFormat[childFormat].label,
					value: null
				};
				valueFormat[childFormat] = null;
			}
		}

		lod_.get(values, pathDict).push({ ...valueFormat });
		lod_.get(cloneDict, path).push({ ...dictFormat });

		setFormDictionary(cloneDict);

		handleInputChange(convertToDefEventParam(path, lod_.get(values, pathDict)));
	};

	/**
	 * If u have PI in form and u click to show them
	 */
	const setPIForm = () => {
		setConfirmHasPI(false);
		const onSuccess = res => {
			setValues(res.valueDictionary);
			setFormDictionary(res.formBuilderDictionary);
			setPI(true);
		};
		if (target) {
			dispatch(
				FormActions.getItemByID(selectedId, target, routeDict, onSuccess, true, flagAccount)
			);
		}
	};

	const setOpenConfirmPI = () => {
		if (values?.hasPIValue) {
			setConfirmHasPI(true);
		} else {
			setPIForm();
		}
	};

	const handleClosePI = () => {
		setConfirmHasPI(false);
	};

	/**
	 * Reset pasword send to auth0
	 */
	const resetPassword = values => {
		if (values.userID) {
			const onSuccess = res => {
				let info = {
					message: i18n.t("FORMS.resetPasswordOK"),
					type: "info"
				};
				dispatch(display(info));
			};
			dispatch(FormActions.resetPassword(values.userID, onSuccess));
		} else {
			let error = {
				message: `Can't send reset password because user don't have userID`,
				type: "error"
			};
			dispatch(display(error));
		}
	};

	/**
	 * bloack user send to auth0
	 */
	const blockUser = values => {
		if (selectedId && selectedUser.authID) {
			const onSuccess = res => {
				let info = {
					message: values ? i18n.t("FORMS.userUnBlockOK") : i18n.t("FORMS.userBlockOK"),
					type: "info"
				};
				setBlockUserSelected(!values);
				dispatch(display(info));
				reloadTable();
			};

			dispatch(FormActions.blockUser(selectedId, selectedUser.authID, !values, onSuccess));
		} else {
			let error = {
				message: `Can't block user because user don't have id`,
				type: "error"
			};

			dispatch(display(error));
		}
	};

	const setCustomeProfileAdd = values => {
		setProfileUser(values);
	};

	const setRoleProfileAdd = values => {
		setRolesProfileUser(values);
	};

	useEffect(() => {
		if (forceEdit && selectedId) {
			setOpenConfirmPI();
		}
	}, [forceEdit]);

	const testStringWhithSpace = str => {
		let chaineSansEspaces = str.trim();
		return chaineSansEspaces === "";
	};

	const addCodes = () => {
		if (!testStringWhithSpace(stringCode) && !testStringWhithSpace(stringLabel)) {
			if (!listCodes.find(objet => objet.value === stringCode)) {
				let cloneListCodes = lod_.cloneDeep(listCodes);
				cloneListCodes.push({ value: stringCode, label: stringLabel });
				setListCodes(cloneListCodes);
				setStringCode("");
				setStringLabel("");
			} else {
				setShowWarning(true);
				setStringWarnAdd(i18n.t("FORMS.uniqueCode"));

				setTimeout(() => {
					setShowWarning(false);
				}, 3000);
			}
		} else {
			setShowWarning(true);
			setStringWarnAdd(i18n.t("FORMS.labelOrCodeRequired"));
			setTimeout(() => {
				setShowWarning(false);
			}, 3000);
		}
	};

	const deleteCodes = index => {
		let cloneListCodes = lod_.cloneDeep(listCodes);
		cloneListCodes.splice(index, 1);
		setListCodes(cloneListCodes);
	};

	const updateCodes = (index, value) => {
		let cloneListCodes = lod_.cloneDeep(listCodes);
		if (!listCodes.find(objet => objet.value === value)) {
			if (!testStringWhithSpace(value)) {
				cloneListCodes[index].value = value;
				setListCodes(cloneListCodes);
			} else {
				setIndexWarnCode(index);
				setShowWarningCode(true);
				setStringWarnCode(i18n.t("FORMS.codeRequired"));
				setTimeout(() => {
					setIndexWarnCode(null);
					setShowWarningCode(false);
					setShowWarning(false);
				}, 3000);
			}
		} else {
			setIndexWarnCode(index);
			setShowWarningCode(true);
			setStringWarnCode(i18n.t("FORMS.uniqueCode"));
			setTimeout(() => {
				setIndexWarnCode(null);
				setShowWarningCode(false);
				setShowWarning(false);
			}, 3000);
		}
	};

	const updateLabel = (index, value) => {
		if (!testStringWhithSpace(value)) {
			let cloneListCodes = lod_.cloneDeep(listCodes);
			cloneListCodes[index].label = value;
			setListCodes(cloneListCodes);
		} else {
			setIndexWarnCode(index);
			setShowWarningLabel(true);
			setTimeout(() => {
				setIndexWarnCode(null);
				setShowWarningLabel(false);
				setShowWarning(false);
			}, 3000);
		}
	};

	const updateDynamic = e => {
		handleInputChange(e);
	};

	let onChangeDynamicTag = (child, list) => {
		if (!list.includes(values?.whitelistDynamic?.params?.attribute)) {
			let e = { target: { name: child, value: list } };
			handleInputChange(e);
		} else {
			setShowWarningWD(true);
			setStringWarnAddWD(i18n.t("FORMS.attributeWD"));
			setTimeout(() => {
				setShowWarningWD(false);
			}, 3000);
		}
	};

	const getDialogWidth = () => {
		if (
			values?.type === "code" ||
			target === "user" ||
			values?.type === "codeImage" ||
			values?.isDynamic === true
		) {
			return "lg";
		} else {
			return "md";
		}
	};

	return (
		<Dialog fullWidth maxWidth={getDialogWidth()} open={open} onClose={close}>
			<Box style={{ display: "flex" }}>
				<DialogTitle>
					{`${isEmpty ? i18n.t("FORMS.LABELS.add") : i18n.t("FORMS.LABELS.update")} ${
						route?.form?.pageLabel ?? route.name
					}`}
				</DialogTitle>
				{!PI && (
					<Tooltip
						title={`${i18n.t("FORMS.edit")} ${route?.form?.pageLabel ?? route.name}`}
						placement="top"
					>
						<IconButton disabled={PI} onClick={() => setOpenConfirmPI()}>
							<EditIcon />
						</IconButton>
					</Tooltip>
				)}
				{!PI && target === "user" && (
					<Tooltip title={i18n.t("FORMS.resetP")}>
						<IconButton disabled={PI} onClick={() => resetPassword(values)}>
							<SyncLockIcon />
						</IconButton>
					</Tooltip>
				)}
				{!PI &&
					target === "user" &&
					permissions?.permissions.includes("access:blockUser") &&
					user?.userID !== values?.userID &&
					!(values?.accountRole === "accountOwner" && user?.accountRole === "accountManager") && (
						<Tooltip
							title={blockUserSelected ? i18n.t("FORMS.unBlockUser") : i18n.t("FORMS.blockUser")}
						>
							<IconButton disabled={PI} onClick={() => blockUser(blockUserSelected)}>
								{blockUserSelected ? <DoDisturbOffIcon /> : <BlockIcon />}
							</IconButton>
						</Tooltip>
					)}
			</Box>
			<DialogContent>
				{confirmHasPI && (
					<Dialog open={confirmHasPI} onClose={handleClosePI}>
						<DialogTitle>{i18n.t("FORMS.showHiddenConfirmTitle")}</DialogTitle>
						<DialogContent>{i18n.t("FORMS.showHiddenConfirm")}</DialogContent>
						<DialogActions>
							<MDButton autoFocus onClick={handleClosePI} variant="outlined" color="info">
								{i18n.t("FORMS.cancel")}
							</MDButton>
							<MDButton onClick={setPIForm} color="info" variant="contained" autoFocus>
								{i18n.t("FORMS.validate")}
							</MDButton>
						</DialogActions>
					</Dialog>
				)}

				<MDBox display="flex">
					<MDBox
						display="flex"
						flexDirection="column"
						width={
							routeDict === "dictionary" &&
							(values?.type === "code" ||
								values?.type === "codeImage" ||
								values?.isDynamic === true)
								? "60%"
								: "100%"
						}
					>
						<FormLayerObject
							target={target}
							fieldChild={formDictionary}
							regexEmail={regexEmail}
							listAPIs={listAPIs}
							isEmpty={isEmpty}
							values={values}
							PI={PI}
							formDictionary={formDictionary}
							customErrors={customErrors}
							userLangage={user?.language?.toLowerCase() || "fr"}
							root=""
							listOfRoles={listOfRoles}
							handleInputChangeValidate={handleInputChangeValidate}
							listOfRolesAssistant={listOfRolesAssistant}
							handleRemoveProduct={handleRemoveProduct}
							handleAddProduct={handleAddProduct}
							contextDictionary={contextDictionary}
							routeDict={routeDict}
							listSkills={listSkills}
						/>
					</MDBox>
					{/* Ajout du tableau de profiles spécifique aux users */}
					{isEmpty && target === "user" && (
						<SelectProfile
							listProfile={profileUser}
							listOfRoles={listOfRoles}
							rolesProfileUser={rolesProfileUser}
							setProfileUser={item => setCustomeProfileAdd(item)}
							setRoleProfile={item => setRoleProfileAdd(item)}
							listAssistant={assistants}
							listOfRolesAssistant={listOfRolesAssistant}
						></SelectProfile>
					)}
					{routeDict === "dictionary" &&
						(values?.type === "code" || values?.type === "codeImage") && (
							<DictionaryCode
								stringCode={stringCode}
								setStringCode={setStringCode}
								stringLabel={stringLabel}
								setStringLabel={setStringLabel}
								listCodes={listCodes}
								showWarning={showWarning}
								showWarningCode={showWarningCode}
								stringWarnCode={stringWarnCode}
								stringWarnAdd={stringWarnAdd}
								indexWarnCode={indexWarnCode}
								addCodes={addCodes}
								updateCodes={updateCodes}
								showWarningLabel={showWarningLabel}
								updateLabel={updateLabel}
								deleteCodes={deleteCodes}
							/>
						)}
					{routeDict === "dictionary" && values?.isDynamic === true && (
						<DictionaryDynamic
							values={values}
							updateDynamic={updateDynamic}
							listAPIs={listAPIs}
							PI={PI}
							showWarningWD={showWarningWD}
							stringWarnAddWD={stringWarnAddWD}
							onChangeDynamicTag={onChangeDynamicTag}
						/>
					)}
				</MDBox>
			</DialogContent>
			<DialogActions>
				<MDButton variant="outlined" color="info" onClick={close}>
					{i18n.t("FORMS.cancel")}
				</MDButton>
				<MDButton
					variant="contained"
					color="info"
					onClick={sumbit}
					disabled={loadingValidate || hasErrors || !PI}
				>
					{i18n.t("FORMS.validate")}
					{loadingValidate && (
						<Fade in={loadingValidate}>
							<MDBox display="flex" alignItems="center" ml={2}>
								<CircularProgress size={20} color="light" />
							</MDBox>
						</Fade>
					)}
				</MDButton>
			</DialogActions>
		</Dialog>
	);
}
