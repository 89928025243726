/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-underscore-dangle */

/**
 * #######################################################@
 *
 * Profile chart settings
 *
 * #######################################################@
 */
import "./style.css";

import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Step,
	StepLabel,
	Stepper
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import MDButton from "components/Basics/MDButton";
import MDBox from "components/Basics/MDBox";
import i18n from "i18n";
import lod_ from "lodash";

import { useSelector, useDispatch } from "react-redux";
import FormActions from "redux-react/actions/formAction";
import ProfileChoiceStepUser from "./steps/1. ProfileChoiceStepUser";
import ProfileChoiceStepAssistant from "./steps/2. ProfileChoiceStepAssistant";
import ProfileChoiceStepRole from "./steps/3. ProfileChoiceStepRole";
import ProfileReview from "./steps/4. ProfileReview";

export default function AddProfileDialog({ open, handleCloseDialog, handleSave }) {
	const topContainer = useRef(null);
	// Product skeleton
	const [newProfile, setNewProfile] = useState({});
	const dispatch = useDispatch();

	/**
	 * Active step
	 */
	const [activeStep, setActiveStep] = useState(0);
	/**
	 *
	 * Steps labels
	 */
	const [steps, setSteps] = useState([
		i18n.t("SETTINGS.PROFILE.chooseUser"),
		i18n.t("SETTINGS.PROFILE.chooseAssistant"),
		i18n.t("SETTINGS.PROFILE.chooseRole"),
		i18n.t("SETTINGS.PROFILE.review")
	]);
	/**
	 * Can user go to next step
	 */
	const [stepValid, setStepValid] = useState(false);
	/**
	 * Is last step
	 */
	const isLastStep = activeStep === steps.length - 1;
	/**
	 * Close modale
	 */
	function close(e, reason) {
		handleCloseDialog();
		/**
		 * On component close, reset all states
		 * Timeout to wait for animation end
		 */
		setTimeout(() => {
			setNewProfile({});
			setStepValid(false);
			setActiveStep(0);

			setSteps([
				i18n.t("SETTINGS.PROFILE.chooseUser"),
				i18n.t("SETTINGS.PROFILE.chooseAssistant"),
				i18n.t("SETTINGS.PROFILE.chooseRole"),
				i18n.t("SETTINGS.PROFILE.review")
			]);
		}, 400);
	}
	/**
	 * Submit profile
	 */
	function sumbit() {
		handleSave(newProfile);
		close();
	}

	/**
	 * User can go to next step
	 */
	const validStep = (val = true) => {
		setStepValid(val);
	};

	/**
	 * User select profile
	 */
	const handleSelectUser = (userID, picture) => {
		let copyProduct = lod_.cloneDeep(newProfile);
		copyProduct.userID = userID;
		copyProduct.picture = picture || "";
		setNewProfile(copyProduct);
	};
	/**
	 * Go to next step
	 */
	const handleNext = () => {
		if (activeStep === 1) {
			const onSuccess = res => {
				setActiveStep(activeStep + 1);
				setStepValid(false);
				topContainer?.current?.scrollIntoView();
			};
			dispatch(
				FormActions.getCheckProfile(
					"profile",
					{ userID: newProfile?.userID, assistantID: newProfile?.assistantID },
					onSuccess
				)
			);
		} else {
			setActiveStep(activeStep + 1);
			setStepValid(false);
			topContainer?.current?.scrollIntoView();
		}
	};
	/**
	 * Go to previous step
	 */
	const handleBack = () => {
		setActiveStep(activeStep - 1);
		topContainer?.current?.scrollIntoView();
	};

	/**
	 * User make settings of profile
	 */
	const handleSelectAssistant = (assistantID, codeOffering, name) => {
		let copyProduct = lod_.cloneDeep(newProfile);
		copyProduct.assistantID = assistantID;
		copyProduct.codeOffering = codeOffering;
		copyProduct.nameAssistant = name;
		setNewProfile(copyProduct);
	};

	const handleSelectRole = role => {
		let copyProduct = lod_.cloneDeep(newProfile);
		copyProduct.role = role;
		setNewProfile(copyProduct);
	};
	/**
	 * Get actual step content
	 */
	function getStepContent(stepIndex) {
		switch (stepIndex) {
			case 0:
				return (
					<ProfileChoiceStepUser
						profile={newProfile}
						handleSelectUser={handleSelectUser}
						validStep={validStep}
						handleNext={handleNext}
					/>
				);
			case 1:
				return (
					<ProfileChoiceStepAssistant
						profile={newProfile}
						handleSelectAssistant={handleSelectAssistant}
						validStep={validStep}
						handleNext={handleNext}
					/>
				);

			case 2:
				return (
					<ProfileChoiceStepRole
						profile={newProfile}
						handleSelectRole={handleSelectRole}
						validStep={validStep}
						handleNext={handleNext}
					/>
				);

			case 3:
				return <ProfileReview profile={newProfile} validStep={validStep} />;

			default:
				return null;
		}
	}

	useEffect(() => {
		if (activeStep === 1 && stepValid) {
			handleNext();
		}
	}, [newProfile]);
	/**
	 * Main component
	 */
	return (
		<Dialog
			fullWidth
			maxWidth="lg"
			open={open}
			onClose={close}
			PaperProps={{
				sx: {
					height: "90vh"
				}
			}}
		>
			<DialogTitle>
				<MDBox>
					<Stepper activeStep={activeStep} alternativeLabel>
						{steps.map(label => (
							<Step key={label}>
								<StepLabel>{label}</StepLabel>
							</Step>
						))}
					</Stepper>
				</MDBox>
			</DialogTitle>
			<DialogContent>
				<div data-id="top-container" ref={topContainer}></div>

				<MDBox
					mt={2}
					style={{
						height: "100%"
					}}
				>
					{getStepContent(activeStep)}
				</MDBox>
			</DialogContent>
			<DialogActions>
				<MDButton variant="outlined" color="info" onClick={close}>
					{i18n.t("SETTINGS.cancel")}
				</MDButton>
				{activeStep !== 0 && (
					<MDButton
						disabled={activeStep === 0}
						variant="contained"
						color="light"
						onClick={handleBack}
					>
						{i18n.t("SETTINGS.CHARTS.NEW.STEPS.back")}
					</MDButton>
				)}
				<MDButton
					disabled={!stepValid}
					variant="contained"
					color="info"
					onClick={!isLastStep ? handleNext : sumbit}
				>
					{isLastStep ? i18n.t("SETTINGS.add") : i18n.t("SETTINGS.CHARTS.NEW.STEPS.next")}
				</MDButton>
			</DialogActions>
		</Dialog>
	);
}
