import { display } from "redux-react/reducers/snackBarReducer";
import { api } from "./api";

const actions = {
	getProducts: onSuccess => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/products`,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(display(err.response?.data?.error?.message ?? "none"));
					}
				})
			);
		};
	},
	addProduct: (data, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/product/add`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								message: err.response?.data?.error?.message ?? "none",
								type: "error"
							})
						);
					}
				})
			);
		};
	},
	deleteProduct: (id, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/product/delete`,
					data: {
						id
					},
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								message: err.response?.data?.error?.message ?? "none",
								type: "error"
							})
						);
					}
				})
			);
		};
	}
};

export default actions;
