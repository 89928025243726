import { ExpandMore } from "@mui/icons-material";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Autocomplete,
	Chip,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Tooltip
} from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import MDTypography from "components/Basics/MDTypography";
import i18n from "i18n";
import lod_ from "lodash";

export default function DictionaryDynamic({
	values,
	updateDynamic,
	listAPIs,
	PI,
	showWarningWD,
	stringWarnAddWD,
	onChangeDynamicTag
}) {
	return (
		<MDBox display="flex" width="40%">
			<hr style={{ borderLeft: "1px solid #ccc", height: "100%", margin: "0 10px" }} />
			<MDBox display="flex" flexDirection="column" width="100%">
				<MDTypography variant="h6">{i18n.t("FORMS.addDynamic")}</MDTypography>
				<FormControl style={{ marginTop: "2%" }}>
					<InputLabel id="select-label">{i18n.t("FORMS.apiName")}</InputLabel>
					<Select
						labelId="select-label"
						id="select"
						name="whitelistDynamic.apiName"
						value={values?.whitelistDynamic?.apiName || ""}
						onChange={updateDynamic}
					>
						{listAPIs
							?.sort((a, b) => a.name.localeCompare(b.name))
							?.map((api, index) => (
								<MenuItem key={index} value={api.name}>
									{api.name}
								</MenuItem>
							))}
					</Select>
				</FormControl>

				<FormControl style={{ marginTop: "2%" }}>
					<InputLabel id="select-label">{i18n.t("FORMS.actionAPI")}</InputLabel>
					<Select
						labelId="select-label"
						id="select"
						name="whitelistDynamic.action"
						value={values?.whitelistDynamic?.action || ""} /// Par défaut on laisse getRessourceWithQuery
						onChange={updateDynamic}
					>
						{Object.keys(
							listAPIs.find(item => item.name === values?.whitelistDynamic?.apiName)?.config
								?.action || {}
						).map((apiName, index) => (
							<MenuItem key={index} value={apiName}>
								{apiName}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<Accordion defaultExpanded className="customAccordion" style={{ marginTop: "2%" }}>
					<AccordionSummary
						expandIcon={<ExpandMore />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						{i18n.t("FORMS.pramsAPI")}
					</AccordionSummary>
					<AccordionDetails>
						<MDInput
							style={{ marginTop: "2%" }}
							name="whitelistDynamic.params.attribute"
							className="dialogInput"
							label={i18n.t("FORMS.attributeAPI")}
							key="0"
							value={values?.whitelistDynamic?.params?.attribute || ""}
							disabled={!PI}
							onChange={updateDynamic}
						/>

						<Tooltip
							open={showWarningWD}
							title={stringWarnAddWD}
							placement="right"
							disableFocusListener
							disableHoverListener
							disableTouchListener
						>
							<Autocomplete
								value={
									lod_.isArray(values?.whitelistDynamic?.params?.searchFields)
										? values?.whitelistDynamic?.params?.searchFields
										: []
								}
								onChange={(e, newValue) => {
									onChangeDynamicTag("whitelistDynamic.params.searchFields", newValue);
								}}
								style={{ marginTop: "2%" }}
								readOnly={!PI}
								multiple
								id="tags-filled"
								options={[]}
								freeSolo
								renderTags={(tags, getTagProps) => {
									return tags.map((option, index) => {
										return <Chip key={index} label={option} {...getTagProps({ index })} />;
									});
								}}
								renderInput={params => (
									<MDInput {...params} label={i18n.t("FORMS.searchFieldsAPI")} />
								)}
							/>
						</Tooltip>
					</AccordionDetails>
				</Accordion>
			</MDBox>
		</MDBox>
	);
}
