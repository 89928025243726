/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React base styles
import typography from "assets/theme/base/typography";

function configs(labels, datasets) {
	return {
		data: {
			labels,
			datasets: [...datasets]
		},
		options: {
			responsive: true,
			maintainAspectRatio: false,
			plugins: {
				legend: {
					display: true,
					position: "left"
				}
			},
			interaction: {
				intersect: false,
				mode: "index"
			},
			scales: {
				y: {
					grid: {
						drawBorder: false,
						display: true,
						drawOnChartArea: true,
						drawTicks: false,
						borderDash: [5, 5]
					},
					ticks: {
						display: true,
						padding: 10,
						color: "#b2b9bf",
						font: {
							size: 11,
							family: typography.fontFamily,
							style: "normal",
							lineHeight: 2
						}
					}
				},
				x: {
					grid: {
						drawBorder: false,
						display: false,
						drawOnChartArea: false,
						drawTicks: false,
						borderDash: [5, 5]
					},
					ticks: {
						display: true,
						color: "#b2b9bf",
						padding: 20,
						font: {
							size: 11,
							family: typography.fontFamily,
							style: "normal",
							lineHeight: 2
						}
					}
				}
			}
		}
	};
}

export default configs;
