/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { LinearProgress, Tooltip } from "@mui/material";
import { useRef } from "react";

function convertToPercentage(value) {
	const clampedValue = Math.max(-1, Math.min(1, value));
	const normalizedValue = (clampedValue + 1) / 2;
	const percentageValue = normalizedValue * 100;
	return percentageValue.toFixed(1);
}

const GradientBar = ({ count }) => {
	const containerRef = useRef(null);

	return (
		<MDBox display="flex" justifyContent="end">
			<Tooltip placement="top" title={`${convertToPercentage(count)}%`}>
				<MDBox
					ml={1}
					mb={1}
					mt={1}
					ref={containerRef}
					style={{
						height: "30px",
						borderRadius: "10px",
						overflow: "hidden",
						width: "80%",
						position: "relative",
						backgroundImage: `linear-gradient(to right, #f84f4f, yellow, #2eab2e)`
					}}
				>
					<MDBox
						style={{
							left: `${convertToPercentage(count)}%`,
							position: "absolute",
							top: "50%",
							transform: "translateY(-50%)",
							width: 5,
							height: 20,
							background: "#616161",
							borderRadius: "100px",
							marginLeft: -2.5
						}}
					></MDBox>
				</MDBox>
			</Tooltip>
		</MDBox>
	);
};

const TemperatureCard = ({ title, count, icon }) => {
	return (
		<Card>
			<MDBox display="flex" flexDirection="column" justifyContent="space-between" pt={1} px={2}>
				{icon?.component && (
					<MDBox
						bgColor={icon?.color || "info"}
						variant="gradient"
						coloredShadow={icon?.color || "info"}
						borderRadius="xl"
						display="flex"
						justifyContent="center"
						alignItems="center"
						width="3rem"
						height="3rem"
						color="white"
						mt={-3}
					>
						<Icon fontSize="small" color="inherit">
							{icon?.component}
						</Icon>
					</MDBox>
				)}
				<MDBox textAlign="right" lineHeight={1.25}>
					<MDBox mt={icon.component ? -2 : 0}>
						<MDTypography variant="h6">{title}</MDTypography>
					</MDBox>
					<MDBox>
						<GradientBar count={count} />
					</MDBox>
				</MDBox>
			</MDBox>
		</Card>
	);
};

export default TemperatureCard;
