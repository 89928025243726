import { Fade, Grow } from "@mui/material";
import { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import FormActions from "redux-react/actions/formAction";
import MDBox from "components/Basics/MDBox";
import lod_ from "lodash";

import C from "../constants";
import InformationCardUser from "../components/InformationCardUser";
/**
 * Step 1: Selected the profile type
 */
const ProfileChoiceStepUser = ({ profile, handleSelectUser, validStep, handleNext }) => {
	const dispatch = useDispatch();

	// Handle select profile
	const selectProfile = user => {
		handleSelectUser(user.userID, user.picture);
		validStep();
		handleNext();
	};

	const [userList, setUserList] = useState([]);
	const { user } = useSelector(state => state);

	useEffect(() => {
		if (profile?.userID) {
			validStep();
		}
		const onSuccess = res => {
			setUserList(res.users);
		};

		dispatch(FormActions.getUsers("user", user.accountRole, onSuccess));
	}, []);

	return (
		<MDBox>
			<MDBox mt={3} display="flex" flexDirection="column" justifyContent="space-evenly">
				{userList?.length > 0 && (
					<>
						{userList
							.sort((a, b) => a.userID.localeCompare(b.userID))
							.map((c, index) => {
								return (
									<Grow key={index} in timeout={(index + 1) * 500}>
										<div>
											<InformationCardUser
												element={c}
												onClick={() => selectProfile(c)}
												selected={profile && profile.userID === c.userID}
											/>
										</div>
									</Grow>
								);
							})}
					</>
				)}
			</MDBox>
		</MDBox>
	);
};

export default ProfileChoiceStepUser;
