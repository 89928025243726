import React, { useEffect, useState } from "react";
import _lod from "lodash";

export function useForm(initialValues, validateOnChange = false, validate) {
	const [values, setValues] = useState(initialValues);
	const [errors, setErrors] = useState({});

	/**
	 * Recursive function to update a field
	 */
	const recursiveUpdate = (elts, newValue, length, max, value) => {
		let splitted = elts[length].split("[");
		if (!newValue[splitted[0]]) {
			newValue[splitted[0]] = {};
		}
		if (splitted.length > 1) {
			if (length < max) {
				length += 1;
				return recursiveUpdate(
					elts,
					newValue[splitted[0]][splitted[1].split("]")[0]],
					length,
					max,
					value
				);
			} else {
				newValue[splitted[0]][splitted[1][0]] = value;
				return newValue[splitted[0]][splitted[1][0]];
			}
		} else if (length < max) {
			length += 1;
			return recursiveUpdate(elts, newValue[splitted[0]], length, max, value);
		}
		newValue[elts[length]] = value;
		return newValue[elts[length]];
	};

	const handleInputChange = e => {
		const { name, value } = e.target;
		const elts = name.split(".");
		const newvalues = _lod.cloneDeep(values);

		// console.log(`useForm Debug - ${JSON.stringify({ name, value })}`);

		recursiveUpdate(elts, newvalues, 0, elts.length - 1, value);

		setValues(newvalues);
		if (validateOnChange) {
			setErrors(validate(newvalues));
		} else {
			delete errors[name];
		}
	};

	const resetForm = () => {
		setValues(initialValues);
		setErrors({});
	};

	return {
		values,
		setValues,
		errors,
		setErrors,
		handleInputChange,
		resetForm
	};
}

export function Form({ children, ...other }) {
	/* eslint-disable react/jsx-props-no-spreading */
	return (
		<form autoComplete="off" {...other}>
			{children}
		</form>
	);
}
