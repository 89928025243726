/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/Basics/MDBox";

import "./style.css";
import { createRef, useEffect } from "react";
import { useMaterialUIController } from "context";

function DataTableBodyCell({ noBorder, align, children, override, subTitle, ...rest }) {
	const [controller] = useMaterialUIController();
	const { darkMode } = controller;

	let realClass = `classicCell_${darkMode ? "dark" : "light"}`;
	if (rest.className) {
		realClass += ` ${rest.className}`;
	}

	let style = {};

	style = {
		...style,
		...rest.style
	};

	return (
		<MDBox
			className={realClass}
			component="td"
			textAlign={align}
			py={1.5}
			px={3}
			sx={({ palette: { light }, typography: { size }, borders: { borderWidth } }) => ({
				fontSize: size.sm,
				borderBottom: noBorder ? "none" : `${borderWidth[1]} solid ${light.main}`
			})}
			style={style}
		>
			<MDBox
				style={{
					display: "flex",
					flexDirection: "column"
				}}
			>
				<MDBox
					display="inline-block"
					width="max-content"
					color="text"
					sx={{ verticalAlign: "middle" }}
				>
					{override ?? children}
				</MDBox>
				{subTitle && (
					<MDBox
						display="inline-block"
						width="max-content"
						color="text"
						sx={{ verticalAlign: "middle" }}
						style={{
							fontWeight: "normal",
							fontSize: "0.8rem",
							fontStyle: "italic"
						}}
					>
						{subTitle}
					</MDBox>
				)}
			</MDBox>
		</MDBox>
	);
}

// Setting default values for the props of DataTableBodyCell
DataTableBodyCell.defaultProps = {
	noBorder: false,
	align: "left"
};

// Typechecking props for the DataTableBodyCell
DataTableBodyCell.propTypes = {
	children: PropTypes.node.isRequired,
	noBorder: PropTypes.bool,
	align: PropTypes.oneOf(["left", "right", "center"])
};

export default DataTableBodyCell;
