/* eslint-disable import/no-mutable-exports */
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers/index";
import apiMiddleware from "./middleware/api";
import { wsMiddleware } from "./middleware/ws";

let initialState = {};

const store = configureStore({
	reducer: rootReducer,
	initialState,
	devTools: process.env.NODE_ENV === "development" || process.env.REACT_APP_ENV === "test1",
	middleware: getDefaultMiddleware => {
		const middlewares = getDefaultMiddleware({
			immutableCheck: false,
			serializableCheck: false
		});
		middlewares.push(apiMiddleware);
		middlewares.push(wsMiddleware);
		return middlewares;
	}
});

export default store;
