/* eslint-disable import/no-import-module-exports */
/**
 *--------------------------------------------------
 *
 * OPERATIONS DOC :
 * https://jsonlogic.com/operations.html
 *
 *--------------------------------------------------
 */
/* FOR ALL TYPE */
const COMP_DEFAULT = [
	{
		type: "title",
		title: "Opérateurs de base"
	},
	{
		code: "element_is_contains_in_array",
		operator: "in",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.inArrayValue",
		type: "array"
	},
	{
		code: "element_exist",
		operator: "!=",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.elementExist",
		value: null
	},
	{
		code: "element_is_in_array",
		operator: "in",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.inArray",
		type: "array"
	},

	{
		type: "divider"
	},
	{
		type: "title",
		title: "Opérateurs spécifiques"
	}
];
/* STRING */
const COMP_STRING = [
	...COMP_DEFAULT,

	{
		code: "string_contain",
		operator: "in",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.in",
		type: "string"
	},

	{
		code: "string_not_equal",
		operator: "!=",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.ne",
		type: "string"
	},
	{
		code: "string_equal",
		operator: "==",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.eq",
		type: "string"
	}
];
/* NUMBER */
const COMP_NUMBER = [
	...COMP_DEFAULT,

	{
		code: "number_not_equal",
		operator: "!=",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.ne"
	},
	{
		code: "number_equal",
		operator: "==",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.eq"
	},

	{
		code: "number_less_than",
		operator: "<",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.lt"
	},
	{
		code: "number_less_than_or_equal",
		operator: "<=",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.lte"
	},
	{
		code: "number_greater_than",
		operator: ">",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.gt"
	},
	{
		code: "number_greater_than_or_equal",
		operator: ">=",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.gte"
	}
];
/* CODE */
const COMP_CODE = [
	...COMP_DEFAULT,

	{
		code: "code_not_equal",
		operator: "!=",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.ne"
	},
	{
		code: "code_equal",
		operator: "==",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.eq"
	}
];
/* DATE */
const COMP_DATE = [
	...COMP_DEFAULT,
	{
		code: "date_equal",
		operator: ">",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.gt"
	},
	{
		code: "date_not_equal",
		operator: "<",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.lt"
	}
];

export default {
	COMP_STRING,
	COMP_NUMBER,
	COMP_CODE,
	COMP_DATE
};
