/* eslint-disable no-unreachable */
import { Grid, Typography } from "@mui/material";
import { LoadingComplexStatisticsCard } from "components/Advanced/Cards/StatisticsCards/ComplexStatisticsCard";
import HorizontalBarChart from "components/Advanced/Charts/BarCharts/HorizontalBarChart";
import VerticalBarChart from "components/Advanced/Charts/BarCharts/VerticalBarChart";
import DefaultDoughnutChart from "components/Advanced/Charts/DoughnutCharts/DefaultDoughnutChart";
import PieChart from "components/Advanced/Charts/PieChart";
import MDBox from "components/Basics/MDBox";
import StackedChart from "components/Advanced/Charts/StackedChart";
import CrossTable from "../crossTable";

const ProportionChart = ({ chart, data, ...rest }) => {
	function getProporiton(item) {
		return item.proportion ?? item.total;
	}
	function mapData(chart, data) {
		let skeleton;

		switch (chart.display.subType) {
			case "pie":
			case "donut":
				skeleton = {
					labels: [],
					datasets: {
						label: chart.display.label ?? "",
						backgroundColors: [],
						data: []
					}
				};

				data.map(item => {
					skeleton.labels.push(item.label ?? item.name);
					skeleton.datasets.backgroundColors.push(item.color);
					skeleton.datasets.data.push(getProporiton(item));
				});

				if (chart.display.datalabels) {
					skeleton.datasets.datalabels = {
						opacity: 1,
						color: "white",
						backgroundColor: "rgba(0, 0, 0, 0.5)",
						borderRadius: 4,
						padding: 4,
						formatter: (value, context) => {
							if (context.dataset?.label) {
								return `${value} ${context.dataset.label}`;
							}
							return value;
						},
						anchor: "end",
						align: "end"
					};
				} else {
					skeleton.datasets.datalabels = {};
				}

				return skeleton;
			case "verticalBar":
			case "horizontalBar":
				skeleton = {
					options: chart.options,
					labels: [],
					datasets: [
						{
							label: chart.display.label ?? "",
							backgroundColor: [],
							data: []
						}
					]
				};

				if (data.datasets) {
					data.options = chart.options ?? {};
					return data;
				}

				data.map(item => {
					skeleton.datasets[0].backgroundColor.push(item.color);
					skeleton.labels.push(item.label ?? item.name);
					skeleton.datasets[0].data.push(getProporiton(item));
				});

				if (chart.display.datalabels) {
					skeleton.datasets[0].datalabels = {
						opacity: 1,
						color: "white",
						backgroundColor: "rgba(0, 0, 0, 0.5)",
						borderRadius: 4,
						padding: 4,
						formatter: (value, context) => {
							if (context.dataset?.label) {
								return `${value} ${context.dataset.label}`;
							}
							return value;
						}
					};
				} else {
					skeleton.datasets[0].datalabels = {};
				}
				return skeleton;
			default:
				return data;
		}
	}

	function getCorrectGraph(chart, data) {
		switch (chart.display.subType) {
			case "table":
				return <CrossTable chart={chart} data={data} />;
			case "pie":
				return (
					<PieChart
						icon={chart.display.icon}
						title={chart.display.title}
						description={chart.display.description}
						chart={mapData(chart, data)}
					/>
				);
			case "donut":
				return (
					<DefaultDoughnutChart
						icon={chart.display.icon}
						title={chart.display.title}
						description={chart.display.description}
						chart={mapData(chart, data)}
					/>
				);
			case "verticalBar":
				return (
					<VerticalBarChart
						icon={chart.display.icon}
						title={chart.display.title}
						description={chart.display.description}
						chart={mapData(chart, data)}
					/>
				);
			case "horizontalBar":
				return (
					<HorizontalBarChart
						icon={chart.display.icon}
						title={chart.display.title}
						description={chart.display.description}
						chart={mapData(chart, data)}
					/>
				);
			case "stackedBar":
				return (
					<StackedChart
						icon={chart.display.icon}
						title={chart.display.title}
						description={chart.display.description}
						chart={mapData(chart, data)}
					/>
				);
			default:
				return <Typography>{`${chart.display.subType} Not implemented`}</Typography>;
		}
	}

	if (!data || data.length === 0) {
		return (
			<Grid item {...rest}>
				<MDBox mb={1.5}>
					<LoadingComplexStatisticsCard
						icon={chart.display.icon}
						title={chart.display.title}
						text={data.length === 0 ? "Aucune donnée" : "Chargement des données"}
					/>
				</MDBox>
			</Grid>
		);
	} else {
		return (
			<Grid item {...rest}>
				{getCorrectGraph(chart, data)}
			</Grid>
		);
	}
};

export default ProportionChart;
