/* eslint-disable no-empty */
/* eslint-disable prefer-destructuring */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const filters = initialState;

const slice = createSlice({
	name: "filters",
	initialState,
	reducers: {
		initFilter: (state, { payload }) => {
			return JSON.parse(localStorage.getItem(`filters`)) || {};
		},
		setFilter: (state, { payload }) => {
			// Set state in store
			let { page, attribute, front, back } = payload;

			state[page] = state[page] || {};
			state[page][attribute] = {
				front,
				back
			};

			localStorage.setItem(`filters`, JSON.stringify(state));
		},
		deleteFilter: (state, { payload }) => {
			let { page, attribute } = payload;
			try {
				delete state[page][attribute];
				let objectInStorage = JSON.parse(localStorage.getItem(`filters`));
				delete objectInStorage[page][attribute];
				localStorage.setItem(`filters`, JSON.stringify(objectInStorage));
			} catch (e) {}
		},
		resetFilters: (state, action) => {
			return initialState;
		}
	}
});

export const { initFilter, setFilter, deleteFilter, resetFilters } = slice.actions;

export default slice.reducer;
