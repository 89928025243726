/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default-member */
/**
 * #######################################################@
 *
 * Charts settings
 *
 * #######################################################@
 */
import "./style.css";

import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Step,
	StepLabel,
	Stepper
} from "@mui/material";
import { useRef, useState } from "react";
import MDButton from "components/Basics/MDButton";
import MDBox from "components/Basics/MDBox";
import i18n from "i18n";
import { useSelector } from "react-redux";
import { createCode } from "../filters";
import ChartChoiceStep from "./steps/1. ChartChoiceStep";
import SubTypeChoiceStep from "./steps/2. SubTypeChoiceStep";
import DataChoiceStep from "./steps/3. DataChoiceStep";
import DisplayChoiceStep from "./steps/4. DisplayChoiceStep";

export default function AddChartDialog({ open, handleCloseDialog, handleSave, collections }) {
	const { accountID } = useSelector(state => state.user);
	const topContainer = useRef(null);
	// Chart skeleton
	const [newChart, setNewChart] = useState({
		documentType: "chart",
		accountID,
		code: createCode(accountID),
		display: {
			icon: {
				component: "equalizer",
				color: "info"
			},
			pages: []
		},
		request: {},
		filters: {},
		options: {},
		active: true
	});
	/**
	 * Active step
	 */
	const [activeStep, setActiveStep] = useState(0);
	/**
	 * Steps labels
	 */
	const [steps, setSteps] = useState([
		i18n.t("SETTINGS.CHARTS.NEW.STEPS.1"),
		i18n.t("SETTINGS.CHARTS.NEW.STEPS.2"),
		i18n.t("SETTINGS.CHARTS.NEW.STEPS.3"),
		i18n.t("SETTINGS.CHARTS.NEW.STEPS.4")
	]);
	/**
	 * Can user go to next step
	 */
	const [stepValid, setStepValid] = useState(false);
	/**
	 * Is last step
	 */
	const isLastStep = activeStep === steps.length - 1;
	/**
	 * Close modale
	 */
	function close(e, reason) {
		handleCloseDialog();
		/**
		 * On component close, reset all states
		 * Timeout to wait for animation end
		 */
		setTimeout(() => {
			setNewChart({
				documentType: "chart",
				accountID,
				code: createCode(accountID),
				display: {
					icon: {
						component: "equalizer",
						color: "info"
					},
					pages: []
				},
				request: {},
				filters: {},
				options: {},
				active: true
			});
			setStepValid(false);
			setActiveStep(0);
			setSteps([
				i18n.t("SETTINGS.CHARTS.NEW.STEPS.1"),
				i18n.t("SETTINGS.CHARTS.NEW.STEPS.2"),
				i18n.t("SETTINGS.CHARTS.NEW.STEPS.3"),
				i18n.t("SETTINGS.CHARTS.NEW.STEPS.4")
			]);
		}, 400);
	}
	/**
	 * Submit chart
	 */
	function sumbit() {
		handleSave(newChart);
		close();
	}
	/**
	 * User select chart type
	 */
	const handleSelectChart = chart => {
		setNewChart(c => {
			c.type = chart.type;
			return c;
		});

		setSteps([
			`${i18n.t("SETTINGS.CHARTS.NEW.STEPS.1")} (${i18n.t(chart.title)})`,
			...steps.slice(1, steps.length)
		]);
	};
	/**
	 * User select chart subType
	 */
	const handleSelectSubType = subType => {
		setNewChart(chart => {
			chart.display.subType = subType.code;
			return chart;
		});

		setSteps([
			steps[0],
			`${i18n.t("SETTINGS.CHARTS.NEW.STEPS.2")} (${i18n.t(subType.title)})`,
			...steps.slice(2, steps.length)
		]);
	};
	/**
	 * User edit chart's request
	 */
	const handleSubmitDatas = request => {
		setNewChart(chart => {
			chart.request = request;
			return chart;
		});
	};
	/**
	 * User edit chart's filters
	 */
	const handleSubmitFilters = filters => {
		setNewChart(chart => {
			chart.filters = filters;
			return chart;
		});
	};
	/**
	 * User edit chart's options
	 */
	const handleSubmitOptions = options => {
		setNewChart(chart => {
			chart.options = options;
			return chart;
		});
	};
	/**
	 * User can go to next step
	 */
	const validStep = (val = true) => {
		setStepValid(val);
	};
	/**
	 * Get actual step content
	 */
	function getStepContent(stepIndex) {
		switch (stepIndex) {
			case 0:
				return (
					<ChartChoiceStep
						chart={newChart}
						handleSelectChart={handleSelectChart}
						validStep={validStep}
					/>
				);
			case 1:
				return (
					<SubTypeChoiceStep
						chart={newChart}
						handleSelectSubType={handleSelectSubType}
						validStep={validStep}
					/>
				);
			case 2:
				return (
					<DataChoiceStep
						chart={newChart}
						handleSubmitDatas={handleSubmitDatas}
						collections={collections}
						validStep={validStep}
						handleSubmitFilters={handleSubmitFilters}
						handleSubmitOptions={handleSubmitOptions}
					/>
				);
			case 3:
				return <DisplayChoiceStep chart={newChart} setChart={setNewChart} validStep={validStep} />;
			default:
				return null;
		}
	}
	/**
	 * Go to next step
	 */
	const handleNext = () => {
		setActiveStep(activeStep + 1);
		setStepValid(false);
		topContainer?.current?.scrollIntoView();
	};
	/**
	 * Go to previous step
	 */
	const handleBack = () => {
		setActiveStep(activeStep - 1);
		topContainer?.current?.scrollIntoView();
	};

	/**
	 * Main component
	 */
	return (
		<Dialog
			fullWidth
			maxWidth="xxxl"
			PaperProps={{
				sx: {
					height: "95%"
				}
			}}
			open={open}
			onClose={close}
		>
			<DialogTitle>
				<MDBox>
					<Stepper activeStep={activeStep} alternativeLabel>
						{steps.map(label => (
							<Step key={label}>
								<StepLabel>{label}</StepLabel>
							</Step>
						))}
					</Stepper>
				</MDBox>
			</DialogTitle>
			<DialogContent>
				<div data-id="top-container" ref={topContainer}></div>

				<MDBox mt={2}>{getStepContent(activeStep)}</MDBox>
			</DialogContent>
			<DialogActions>
				<MDButton variant="outlined" color="dark" onClick={close}>
					{i18n.t("SETTINGS.cancel")}
				</MDButton>
				<MDButton
					disabled={activeStep === 0}
					variant="contained"
					color="light"
					onClick={handleBack}
				>
					{i18n.t("SETTINGS.CHARTS.NEW.STEPS.back")}
				</MDButton>
				<MDButton
					disabled={!stepValid}
					variant="contained"
					color="dark"
					onClick={!isLastStep ? handleNext : sumbit}
				>
					{isLastStep ? i18n.t("SETTINGS.add") : i18n.t("SETTINGS.CHARTS.NEW.STEPS.next")}
				</MDButton>
			</DialogActions>
		</Dialog>
	);
}
