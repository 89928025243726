import lod_ from "lodash";
import NotificationItem from "components/Advanced/Items/NotificationItem";
import MDBadge from "components/Basics/MDBadge";
import { useMaterialUIController } from "context";
import { navbarIconButton } from "components/Advanced/Navbars/DashboardNavbar/styles";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { selectCurrentProfile, selectNotificationsList } from "redux-react/reducers/profileReducer";
import "./style.css";
/**
 * #######################################################@
 *
 * SnackBar component
 *
 * Used to display notifications
 *
 * #######################################################@
 */
import { Menu, IconButton, Icon } from "@mui/material";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { viewedAllNotifications } from "redux-react/reducers/notificationsReducer";

const Notifications = () => {
	const notificationsList = useSelector(selectNotificationsList);
	const dispatch = useDispatch();

	const [openMenu, setOpenMenu] = useState(false);

	const handleOpenMenu = event => {
		setOpenMenu(event.currentTarget);
		dispatch(viewedAllNotifications());
	};
	const handleCloseMenu = () => setOpenMenu(false);

	const [controller] = useMaterialUIController();
	const { darkMode } = controller;

	// Styles for the navbar icons
	const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
		color: () => (darkMode ? white.main : dark.main)
	});

	const renderMenu = () => (
		<Menu
			anchorEl={openMenu}
			anchorReference={null}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "left"
			}}
			open={Boolean(openMenu)}
			onClose={handleCloseMenu}
			// width fixed
			PaperProps={{
				style: {
					width: "30vw",
					padding: 0,
					margin: 0
				}
			}}
		>
			<MDBox sx={{ p: 2, borderBottom: 1, borderColor: "#00000015" }}>
				<MDTypography variant="h6" fontWeight="medium" color="textPrimary">
					Notifications
				</MDTypography>
			</MDBox>
			{notificationsList.length === 0 && (
				<MDBox display="flex" alignItems="center" justifyContent="center" m={1}>
					<MDBox display="flex" alignItems="center" justifyContent="center" p={2}>
						<MDTypography variant="caption" style={{ opacity: 0.7 }} fontWeight="bold">
							Aucune notification
						</MDTypography>
					</MDBox>
				</MDBox>
			)}
			{notificationsList.length > 0 &&
				lod_
					.cloneDeep(notificationsList)
					.reverse()
					.map((notification, index) => {
						return (
							<NotificationItem
								key={index}
								id={notification.id}
								icon={notification.icon}
								message={notification.message}
								ts={notification.ts}
								type={notification.type}
								viewed={notification.viewed}
							/>
						);
					})}
		</Menu>
	);

	return (
		<>
			<IconButton
				size="small"
				disableRipple
				color="inherit"
				sx={navbarIconButton}
				aria-controls="notification-menu"
				aria-haspopup="true"
				variant="contained"
				onClick={handleOpenMenu}
			>
				<MDBadge
					badgeContent={notificationsList.filter(n => !n.viewed).length}
					color="error"
					size="xs"
					circular
				>
					<Icon sx={iconsStyle}>notifications</Icon>
				</MDBadge>
			</IconButton>
			{renderMenu()}
		</>
	);
};

export const nowHourMinute = () => {
	const now = new Date();
	const hour = now.getHours();
	const minute = now.getMinutes();
	return `${String(hour).padStart(2, "0")}:${String(minute).padStart(2, "0")}`;
};

export default Notifications;
