import { useRef } from "react";
import lod_ from "lodash";
import { Accordion, AccordionDetails, AccordionSummary, Box, Icon } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import MDBox from "components/Basics/MDBox";
import MDButton from "components/Basics/MDButton";
import i18n from "i18n";
import InputType from "../Inputs";
import FormLayerArray from "./FormLayerArray";

const useStyles = {
	numberCircle: {
		width: "30px",
		height: "30px",
		padding: "6px 0px",
		borderRadius: "15px",
		textAlign: "center",
		fontSize: "14px",
		lineHeight: 1.42857,
		backgroundColor: "#dfe6e9"
	}
};

export default function FormLayerObject({
	target,
	contextDictionary,
	fieldChild,
	values,
	handleInputChangeValidate,
	userLangage,
	root,
	formDictionary,
	listSkills,
	handleRemoveProduct,
	handleAddProduct,
	customErrors,
	PI,
	isEmpty,
	routeDict,
	regexEmail,
	listAPIs,
	listOfRoles,
	listOfRolesAssistant
}) {
	const classes = useStyles;

	const scrollBottom = useRef(null);

	const addProduc = (rootPath, root) => {
		handleAddProduct(rootPath, root);
		scrollBottom.current.scrollIntoView({ behavior: "smooth" });
	};

	return (
		<>
			{Object.keys(fieldChild)
				.sort((a, b) => fieldChild[a].order - fieldChild[b].order)
				.map((child, key) => {
					let rootPath = root === "" ? `${child}` : root + `.${child}`;
					if (fieldChild[child].type !== "level" && typeof fieldChild[child] === "object") {
						let formConfig = lod_.get(formDictionary, rootPath);
						if (Array.isArray(fieldChild[child])) {
							// return (
							// 	<>
							// 		{targetForm !== "profile" && (
							// 			<MDBox mb={1}>
							// 				<MDButton
							// 					size="small"
							// 					style={{ height: "100%", marginRight: "0.75rem" }}
							// 					variant="contained"
							// 					color="info"
							// 					onClick={() => addProduc(rootPath, root)}
							// 					disabled={!PI}
							// 				>
							// 					<Icon>add</Icon>&nbsp;{i18n.t("FORMS.addProduct")}
							// 				</MDButton>
							// 			</MDBox>
							// 		)}
							// 		<ChildAccordionMapper
							// 			child={fieldChild[child]}
							// 			isEmpty={isEmpty}
							// 			classes={classes}
							// 			values={values}
							// 			root={root}
							// 			handleRemoveProduct={handleRemoveProduct}
							// 			rootPath={rootPath}
							// 			PI={PI}
							// 			userLangage={userLangage}
							// 			customErrors={customErrors}
							// 			handleInputChangeValidate={handleInputChangeValidate}
							// 			routeDict={routeDict}
							// 		/>
							// 		<div ref={scrollBottom}></div>
							// 	</>
							// );
						} else {
							return (
								<Box key={key}>
									<InputType
										target={target}
										regexEmail={regexEmail}
										listAPIs={listAPIs}
										child={rootPath}
										whitelistDynamic={formConfig?.whitelistDynamic}
										isArray={formConfig?.isArray}
										whitelist={formConfig?.whitelist}
										isDisplay={formConfig?.display}
										listOfRoles={listOfRoles}
										codes={formConfig?.codes}
										label={fieldChild[child][userLangage]}
										isEdit={fieldChild[child]?.isEdit}
										defaultValueForItem={fieldChild[child]?.defaultValue}
										key={key}
										PI={PI}
										isEmpty={isEmpty}
										type={formConfig?.type}
										valueForm={values}
										value={lod_.get(values, rootPath)}
										customErrors={lod_.get(customErrors, rootPath)}
										onChange={e => handleInputChangeValidate(e, formConfig, rootPath)}
										values={values}
										listSkills={listSkills}
										routeDict={routeDict}
										listOfRolesAssistant={listOfRolesAssistant}
									></InputType>
								</Box>
							);
						}
					}

					if (
						fieldChild[child].type === "level" &&
						typeof fieldChild[child] === "object" &&
						!fieldChild[child].isArray
					) {
						let label = contextDictionary[child].label[userLangage];
						return (
							<Accordion style={{ margin: "0em 0.5em 1em " }} className="customAccordion" key={key}>
								<AccordionSummary
									expandIcon={<ExpandMore />}
									aria-controls="panel1a-content"
									id="panel1a-header"
								>
									{label}
								</AccordionSummary>
								<AccordionDetails>
									<FormLayerObject
										target={target}
										fieldChild={fieldChild[child]}
										isEmpty={isEmpty}
										userLangage={userLangage}
										root={rootPath}
										values={values}
										listSkills={listSkills}
										key={key}
										PI={PI}
										handleInputChangeValidate={handleInputChangeValidate}
										formDictionary={formDictionary}
										handleRemoveProduct={handleRemoveProduct}
										handleAddProduct={handleAddProduct}
										customErrors={customErrors}
									/>
								</AccordionDetails>
							</Accordion>
						);
					} else if (fieldChild[child].type === "level" && fieldChild[child].isArray) {
						let label = contextDictionary[child].label[userLangage];
						return (
							<Accordion style={{ margin: "0em 0.5em 1em " }} className="customAccordion" key={key}>
								<AccordionSummary
									expandIcon={<ExpandMore />}
									aria-controls="panel1a-content"
									id="panel1a-header"
								>
									{label}
								</AccordionSummary>
								<AccordionDetails>
									<MDBox mb={1}>
										<MDButton
											size="small"
											style={{ height: "100%", marginRight: "0.75rem" }}
											variant="contained"
											color="info"
											onClick={() => addProduc(rootPath, rootPath)}
											disabled={!PI}
										>
											<Icon>add</Icon>&nbsp;{i18n.t("FORMS.addProduct")}
										</MDButton>
									</MDBox>
									{fieldChild[child].value.map((childArray, key) => {
										let rootArray = rootPath + `[${key}]`;

										let rootArrayDico = rootPath + `.value[${key}]`;

										return (
											<FormLayerArray
												key={key}
												target={target}
												fieldChild={fieldChild[child].value[key]}
												index={key}
												contextDictionary={contextDictionary}
												isEmpty={isEmpty}
												title={fieldChild[child][userLangage]}
												classes={classes}
												values={values}
												root={rootArray}
												handleRemoveProduct={handleRemoveProduct}
												rootPath={rootArrayDico}
												PI={PI}
												formDictionary={formDictionary}
												userLangage={userLangage}
												customErrors={customErrors}
												handleAddProduct={addProduc}
												handleInputChangeValidate={handleInputChangeValidate}
												routeDict={routeDict}
												listOfRoles={listOfRoles}
												listOfRolesAssistant={listOfRolesAssistant}
											/>
										);
									})}
									<div ref={scrollBottom}></div>
								</AccordionDetails>
							</Accordion>
						);
					} else {
						return null;
					}
				})}
		</>
	);
}
